import FaceItem from '@/app/com/main/module/support/face/data/FaceItem';

import FaceCategory from '@/app/com/main/module/support/face/data/FaceCategory';
import AbstractBag from '../base/AbstractBag';

export default abstract class AbstractClassicalBag extends AbstractBag {

    public put(face: string, visible: boolean) {
        if (face) {
            const faceCategory = this.getFaceCategory();
            const array = face.split(',');
            if (array && array.length > 1) {
                const categoryId = array[0];
                const key = array[1];

                if (this.has(key)) {
                    const text = (array.length > 2) ? array[2] : '';
                    const faceValue: FaceItem = new FaceItem();
                    faceValue.categoryId = categoryId;
                    faceValue.key = key;
                    faceValue.text = text;
                    faceValue.width = 24;
                    faceValue.height = 24;
                    faceValue.visible = visible;
                    faceCategory.faces.push(faceValue);
                }
            }
        }
    }

    public initialize(faceCategory: FaceCategory): void {
        this.getFaceCategory().visible = false;
        faceCategory.name = '默认表情';
        faceCategory.id = 'classical';

        this.put('classical,0,惊讶', true);
        this.put('classical,1,撇嘴', true);
        this.put('classical,2,色', true);
        this.put('classical,3,发呆', true);
        this.put('classical,4,得意', true);
        this.put('classical,5,流泪', true);
        this.put('classical,6,害羞', true);
        this.put('classical,7,闭嘴', true);
        this.put('classical,8,睡', true);
        this.put('classical,9,大哭', true);
        this.put('classical,10,尴尬', true);
        this.put('classical,11,发怒', true);
        this.put('classical,12,调皮', true);
        this.put('classical,13,呲牙', true);
        this.put('classical,14,微笑', true);
        this.put('classical,15,难过', true);
        this.put('classical,16,酷', true);
        this.put('classical,17,非典', true);
        this.put('classical,18,抓狂', true);
        this.put('classical,19,吐', true);
        this.put('classical,20,偷笑', true);
        this.put('classical,21,可爱', true);
        this.put('classical,22,白眼', true);
        this.put('classical,23,傲慢', true);
        this.put('classical,24,饥饿', true);
        this.put('classical,25,困', true);
        this.put('classical,26,惊恐', true);
        this.put('classical,27,流汗', true);
        this.put('classical,28,憨笑', true);
        this.put('classical,29,大兵', true);
        this.put('classical,30,奋斗', true);
        this.put('classical,31,咒骂', true);
        this.put('classical,32,疑问', true);
        this.put('classical,33,嘘...', true);
        this.put('classical,34,晕', true);
        this.put('classical,35,折磨', true);
        this.put('classical,36,衰', true);
        this.put('classical,37,骷髅', true);
        this.put('classical,38,敲打', true);
        this.put('classical,39,再见', true);
        this.put('classical,40,闪人', false);
        this.put('classical,41,发抖', false);
        this.put('classical,42,爱情', false);
        this.put('classical,43,跳跳', false);
        this.put('classical,44,找', false);
        this.put('classical,45,美眉', false);
        this.put('classical,46,猪头', true);
        this.put('classical,47,猫咪', false);
        this.put('classical,48,小狗', false);
        this.put('classical,49,拥抱', true);
        this.put('classical,50,人民币', false);
        this.put('classical,51,灯泡', false);
        this.put('classical,52,酒杯', false);
        this.put('classical,53,蛋糕', true);
        this.put('classical,54,闪电', true);
        this.put('classical,55,炸弹', true);
        this.put('classical,56,刀', true);
        this.put('classical,57,足球', true);
        this.put('classical,58,音乐', false);
        this.put('classical,59,便便', true);
        this.put('classical,60,咖啡', true);
        this.put('classical,61,饭', true);
        this.put('classical,62,药丸', true);
        this.put('classical,63,玫瑰', true);
        this.put('classical,64,凋谢', true);
        this.put('classical,65,示爱', true);
        this.put('classical,66,爱心', true);
        this.put('classical,67,心碎', true);
        this.put('classical,68,会议', false);
        this.put('classical,69,礼物', true);
        this.put('classical,70,电话', false);
        this.put('classical,71,时间', false);
        this.put('classical,72,邮件', false);
        this.put('classical,73,电视', false);
        this.put('classical,74,太阳', true);
        this.put('classical,75,月亮', true);
        this.put('classical,76,强', true);
        this.put('classical,77,弱', true);
        this.put('classical,78,握手', true);
        this.put('classical,79,胜利', true);
        this.put('classical,80,多多', false);
        this.put('classical,81,美女', false);
        this.put('classical,82,汉良', false);
        this.put('classical,83,毛毛', false);
        this.put('classical,84,Q仔', false);
        this.put('classical,85,飞吻', false);
        this.put('classical,86,怄火', false);
        this.put('classical,87,白酒', false);
        this.put('classical,88,汽水', false);
        this.put('classical,89,西瓜', true);
        this.put('classical,90,下雨', false);
        this.put('classical,91,多云', false);
        this.put('classical,92,雪人', false);
        this.put('classical,93,星星', false);
        this.put('classical,94,女', false);
        this.put('classical,95,男', false);
        this.put('classical,96,冷汗', true);
        this.put('classical,97,擦汗', true);
        this.put('classical,98,抠鼻', true);
        this.put('classical,99,鼓掌', true);
        this.put('classical,100,糗大了', true);
        this.put('classical,101,坏笑', true);
        this.put('classical,102,左哼哼', true);
        this.put('classical,103,右哼哼', true);
        this.put('classical,104,哈欠', true);
        this.put('classical,105,鄙视', true);
        this.put('classical,106,委屈', true);
        this.put('classical,107,快哭了', true);
        this.put('classical,108,阴险', true);
        this.put('classical,109,亲亲', true);
        this.put('classical,110,吓', true);
        this.put('classical,111,可怜', true);
        this.put('classical,112,菜刀', true);
        this.put('classical,113,啤酒', true);
        this.put('classical,114,篮球', true);
        this.put('classical,115,乒乓', true);
        this.put('classical,116,示爱', true);
        this.put('classical,117,瓢虫', true);
        this.put('classical,118,抱拳', true);
        this.put('classical,119,勾引', true);
        this.put('classical,120,拳头', true);
        this.put('classical,121,差劲', true);
        this.put('classical,122,爱你', true);
        this.put('classical,123,NO', true);
        this.put('classical,124,OK', true);
        this.put('classical,125,转圈', false);
        this.put('classical,126,磕头', false);
        this.put('classical,127,回头', false);
        this.put('classical,128,跳绳', false);
        this.put('classical,129,挥手', false);
        this.put('classical,130,激动', false);
        this.put('classical,131,街舞', false);
        this.put('classical,132,献吻', false);
        this.put('classical,133,左太极', false);
        this.put('classical,134,右太极', false);
        this.put('classical,135,招财进宝', true);
        this.put('classical,136,双喜', true);
        this.put('classical,137,鞭炮', true);
        this.put('classical,138,灯笼', true);
        this.put('classical,139,发财', true);
        this.put('classical,140,K歌', true);
        this.put('classical,141,购物', true);
        this.put('classical,142,邮件', true);
        this.put('classical,143,帅', true);
        this.put('classical,144,喝彩', true);
        this.put('classical,145,祈祷', true);
        this.put('classical,146,爆筋', true);
        this.put('classical,147,棒棒糖', true);
        this.put('classical,148,喝奶', true);
        this.put('classical,149,下面', true);
        this.put('classical,150,香蕉', true);
        this.put('classical,151,飞机', true);
        this.put('classical,152,开车', true);
        this.put('classical,153,高铁左车头', true);
        this.put('classical,154,车厢', true);
        this.put('classical,155,高铁右车头', true);
        this.put('classical,156,多云', true);
        this.put('classical,157,下雨', true);
        this.put('classical,158,钞票', true);
        this.put('classical,159,熊猫', true);
        this.put('classical,160,灯泡', true);
        this.put('classical,161,风车', true);
        this.put('classical,162,闹钟', true);
        this.put('classical,163,打伞', true);
        this.put('classical,164,彩球', true);
        this.put('classical,165,钻戒', true);
        this.put('classical,166,沙发', true);
        this.put('classical,167,纸巾', true);
        this.put('classical,168,药', true);
        this.put('classical,169,手枪', true);
        this.put('classical,170,青蛙', true);
        this.put('classical,171,茶', true);
        this.put('classical,172,眨眼睛', true);
        this.put('classical,173,泪奔', true);
        this.put('classical,174,无奈', true);
        this.put('classical,175,卖萌', true);
        this.put('classical,176,小纠结', true);
        this.put('classical,177,喷血', true);
        this.put('classical,178,斜眼笑', true);
        this.put('classical,179,doge', true);
        this.put('classical,180,惊喜', true);
        this.put('classical,181,骚扰', true);
        this.put('classical,182,笑哭', true);
        this.put('classical,183,我最美', true);
        this.put('classical,184,河蟹', true);
        this.put('classical,185,羊驼', true);
        this.put('classical,186,栗子', true);
        this.put('classical,187,幽灵', true);
        this.put('classical,188,蛋', true);
        this.put('classical,189,马赛克', true);
        this.put('classical,190,菊花', true);
        this.put('classical,191,肥皂', true);
        this.put('classical,192,红包', true);
        this.put('classical,193,大笑', false);
        this.put('classical,194,不开心', false);
        this.put('classical,195,啊', false);
        this.put('classical,196,冷汗', false);
        this.put('classical,197,冷漠', false);
        this.put('classical,198,呃', false);
        this.put('classical,199,好棒', false);
        this.put('classical,200,拜托', true);
        this.put('classical,201,点赞', true);
        this.put('classical,202,无聊', true);
        this.put('classical,203,托脸', true);
        this.put('classical,204,吃', true);
        this.put('classical,205,送花', true);
        this.put('classical,206,害怕', true);
        this.put('classical,207,花痴', true);
        this.put('classical,208,小样儿', true);
        this.put('classical,209,脸红', true);
        this.put('classical,210,飙泪', true);
        this.put('classical,211,我不看', true);
        this.put('classical,212,托腮', true);
        this.put('classical,213,哇哦', false);


        // this.put('classical,1000,潜水',true);
        // this.put('classical,1001,敬礼',true);
        // this.put('classical,1002,石化',true);
        // this.put('classical,1014,加油',true);
        // this.put('classical,1017,无语',true);
        // this.put('classical,1022,安慰',true);
        // this.put('classical,1024,生病',true);
        // this.put('classical,1027,狂汗',true);
        // this.put('classical,1028,扮鬼脸',true);
        // this.put('classical,1029,叹气',true);
        // this.put('classical,1030,拜托',true);
        // this.put('classical,1034,烦躁',true);
        // this.put('classical,1040,惬意',true);
        // this.put('classical,1042,孤寂',true);
        // this.put('classical,1070,牵手',true);
        // this.put('classical,1071,示爱',true);
        // this.put('classical,1072,情书',true);
        // this.put('classical,1073,月饼',true);
        // this.put('classical,1074,玉兔',true);
    }
}
