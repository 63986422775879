class EmojiInfoBox {

    private list: Array<{ key: string, text: string }> = [];

    public constructor() {
        this.initialize();
    }

    public getList(): Array<{ key: string, text: string }> {
        return this.list;
    }

    private add(data: { key: string, text: string }) {
        this.list.push(data);
    }

    private initialize() {

        const array = [

            {
                key: '😂', name: '笑脸和情感', count: 151, items: [
                    {
                        key: '😄', name: '笑脸', count: 13, items: [
                            {key: '😀', text: '嘿嘿'},
                            {key: '😁', text: '嘻嘻'},
                            {key: '😂', text: '笑哭了'},
                            {key: '😃', text: '哈哈'},
                            {key: '😄', text: '大笑'},
                            {key: '😅', text: '苦笑'},
                            {key: '😆', text: '斜眼笑'},
                            {key: '😇', text: '微笑天使'},
                            {key: '😉', text: '眨眼'},
                            {key: '😊', text: '羞涩微笑'},
                            {key: '🙂', text: '呵呵'},
                            {key: '🙃', text: '倒脸'},
                            {key: '🤣', text: '笑得满地打滚'},
                        ],
                    },
                    {
                        key: '😍', name: '表情脸', count: 9, items: [
                            {key: '☺', text: '微笑'},
                            {key: '😍', text: '花痴'},
                            {key: '😗', text: '亲亲'},
                            {key: '😘', text: '飞吻'},
                            {key: '😙', text: '微笑亲亲'},
                            {key: '😚', text: '羞涩亲亲'},
                            {key: '🤩', text: '好崇拜哦'},
                            {key: '🥰', text: '喜笑颜开'},
                            {key: '🥲', text: '含泪的笑脸'},
                        ],
                    },
                    {
                        key: '😛', name: '吐舌脸', count: 6, items: [
                            {key: '😋', text: '好吃'},
                            {key: '😛', text: '吐舌'},
                            {key: '😜', text: '单眼吐舌'},
                            {key: '😝', text: '眯眼吐舌'},
                            {key: '🤑', text: '发财'},
                            {key: '🤪', text: '滑稽'},
                        ],
                    },
                    {
                        key: '🤔', name: '帯手脸', count: 4, items: [
                            {key: '🤔', text: '想一想'},
                            {key: '🤗', text: '抱抱'},
                            {key: '🤫', text: '安静的脸'},
                            {key: '🤭', text: '不说'},
                        ],
                    },
                    {
                        key: '🤐', name: '中性脸-怀疑脸', count: 10, items: [

                            {key: '😏', text: '得意'},
                            {key: '😐', text: '冷漠'},
                            {key: '😑', text: '无语'},
                            {key: '😒', text: '不高兴'},
                            {key: '😬', text: '龇牙咧嘴'},
                            {key: '😶', text: '沉默'},
                            {key: '🙄', text: '翻白眼'},
                            {key: '🤐', text: '闭嘴'},
                            {key: '🤥', text: '说谎'},
                            {key: '🤨', text: '挑眉'},
                        ],
                    }, {
                        key: '😴', name: '睡脸', count: 5, items: [

                            {key: '😌', text: '松了口气'},
                            {key: '😔', text: '沉思'},
                            {key: '😪', text: '困'},
                            {key: '😴', text: '睡着了'},
                            {key: '🤤', text: '流口水'},
                        ],
                    }, {
                        key: '🤧', name: '病脸', count: 11, items: [

                            {key: '😵', text: '晕头转向'},
                            {key: '😷', text: '感冒'},
                            {key: '🤒', text: '发烧'},
                            {key: '🤕', text: '受伤'},
                            {key: '🤢', text: '恶心'},
                            {key: '🤧', text: '打喷嚏'},
                            {key: '🤮', text: '呕吐'},
                            {key: '🤯', text: '爆炸头'},
                            {key: '🥴', text: '头昏眼花'},
                            {key: '🥵', text: '脸发烧'},
                            {key: '🥶', text: '冷脸'},
                        ],
                    }, {
                        key: '🤠', name: '带帽脸', count: 3, items: [

                            {key: '🤠', text: '牛仔帽脸'},
                            {key: '🥳', text: '聚会笑脸'},
                            {key: '🥸', text: '伪装的脸'},
                        ],
                    }, {
                        key: '😎', name: '眼镜脸', count: 3, items: [

                            {key: '😎', text: '墨镜笑脸'},
                            {key: '🤓', text: '书呆子脸'},
                            {key: '🧐', text: '带单片眼镜的脸'},
                        ],
                    }, {
                        key: '😞', name: '担忧脸', count: 24, items: [

                            {key: '☹', text: '不满'},
                            {key: '😓', text: '汗'},
                            {key: '😕', text: '困扰'},
                            {key: '😖', text: '困惑'},
                            {key: '😞', text: '失望'},
                            {key: '😟', text: '担心'},
                            {key: '😢', text: '哭'},
                            {key: '😣', text: '痛苦'},
                            {key: '😥', text: '失望但如释重负'},
                            {key: '😦', text: '啊'},
                            {key: '😧', text: '极度痛苦'},
                            {key: '😨', text: '害怕'},
                            {key: '😩', text: '累死了'},
                            {key: '😫', text: '累'},
                            {key: '😭', text: '放声大哭'},
                            {key: '😮', text: '吃惊'},
                            {key: '😯', text: '缄默'},
                            {key: '😰', text: '冷汗'},
                            {key: '😱', text: '吓死了'},
                            {key: '😲', text: '震惊'},
                            {key: '😳', text: '脸红'},
                            {key: '🙁', text: '微微不满'},
                            {key: '🥱', text: '打呵欠'},
                            {key: '🥺', text: '恳求的脸'},
                        ],
                    }, {
                        key: '😠', name: '消极脸', count: 8, items: [

                            {key: '☠', text: '骷髅'},
                            {key: '👿', text: '生气的恶魔'},
                            {key: '💀', text: '头骨'},
                            {key: '😈', text: '恶魔微笑'},
                            {key: '😠', text: '生气'},
                            {key: '😡', text: '怒火中烧'},
                            {key: '😤', text: '傲慢'},
                            {key: '🤬', text: '嘴上有符号的脸'},
                        ],
                    }, {
                        key: '💩', name: '装扮脸', count: 8, items: [

                            {key: '👹', text: '食人魔'},
                            {key: '👺', text: '小妖精'},
                            {key: '👻', text: '鬼'},
                            {key: '👽', text: '外星人'},
                            {key: '👾', text: '外星怪物'},
                            {key: '💩', text: '大便'},
                            {key: '🤖', text: '机器人'},
                            {key: '🤡', text: '小丑脸'},
                        ],
                    }, {
                        key: '😸', name: '猫咪脸', count: 9, items: [

                            {key: '😸', text: '微笑的猫'},
                            {key: '😹', text: '笑出眼泪的猫'},
                            {key: '😺', text: '大笑的猫'},
                            {key: '😻', text: '花痴的猫'},
                            {key: '😼', text: '奸笑的猫'},
                            {key: '😽', text: '亲亲猫'},
                            {key: '😾', text: '生气的猫'},
                            {key: '😿', text: '哭泣的猫'},
                            {key: '🙀', text: '疲倦的猫'},
                        ],
                    }, {
                        key: '🙈', name: '猴子脸', count: 3, items: [

                            {key: '🙈', text: '非礼勿视'},
                            {key: '🙉', text: '非礼勿听'},
                            {key: '🙊', text: '非礼勿言'},
                        ],
                    }, {
                        key: '💋', name: '情感', count: 35, items: [

                            {key: '❣', text: '心叹号'},
                            {key: '❤', text: '红心'},
                            {key: '👁🗨', text: '眼睛对话框'},
                            {key: '💋', text: '唇印'},
                            {key: '💌', text: '情书'},
                            {key: '💓', text: '心跳'},
                            {key: '💔', text: '心碎'},
                            {key: '💕', text: '两颗心'},
                            {key: '💖', text: '闪亮的心'},
                            {key: '💗', text: '搏动的心'},
                            // {key: '💘', text: '心中箭了'},
                            {key: '💘', text: '一箭穿心'},
                            {key: '💙', text: '蓝心'},
                            {key: '💚', text: '绿心'},
                            {key: '💛', text: '黄心'},
                            {key: '💜', text: '紫心'},
                            {key: '💝', text: '系有缎带的心'},
                            {key: '💞', text: '舞动的心'},
                            {key: '💟', text: '心型装饰'},
                            {key: '💢', text: '怒'},
                            {key: '💣', text: '炸弹'},
                            {key: '💤', text: '睡着'},
                            {key: '💥', text: '爆炸'},
                            {key: '💦', text: '汗滴'},
                            {key: '💨', text: '尾气'},
                            {key: '💫', text: '头晕'},
                            {key: '💬', text: '话语气泡'},
                            {key: '💭', text: '内心活动气泡'},
                            {key: '💯', text: '一百分'},
                            {key: '🕳', text: '洞'},
                            {key: '🖤', text: '黑心'},
                            {key: '🗨', text: '朝左的话语气泡'},
                            {key: '🗯', text: '愤怒话语气泡'},
                            {key: '🤍', text: '白心'},
                            {key: '🤎', text: '棕心'},
                            {key: '🧡', text: '橙心'},
                        ],
                    }],
            },
            {
                key: '👌', name: '人类和身体', count: 347, items: [{
                    key: '🖐', name: '手掌张开', count: 5, items: [

                        {key: '✋', text: '举起手'},
                        {key: '👋', text: '挥手'},
                        {key: '🖐', text: '手掌'},
                        {key: '🖖', text: '瓦肯举手礼'},
                        {key: '🤚', text: '立起的手背'},
                    ],
                }, {
                    key: '👌', name: '几根手指', count: 8, items: [

                        // {key: '✌', text: '胜利手势'},
                        {key: '✌', text: '胜利'},
                        {key: '👌', text: 'OK'},
                        {key: '🤌', text: '捏手指'},
                        {key: '🤏', text: '捏合的手势'},
                        {key: '🤘', text: '摇滚'},
                        {key: '🤙', text: '给我打电话'},
                        {key: '🤞', text: '交叉的手指'},
                        // {key: '🤟', text: '爱你的手势'},
                        {key: '🤟', text: '爱你'},
                    ],
                }, {
                    key: '👈', name: '一根手指', count: 6, items: [

                        {key: '☝', text: '食指向上指'},
                        {key: '👆', text: '反手食指向上指'},
                        {key: '👇', text: '反手食指向下指'},
                        {key: '👈', text: '反手食指向左指'},
                        {key: '👉', text: '反手食指向右指'},
                        {key: '🖕', text: '竖中指'},
                    ],
                }, {
                    key: '👍', name: '合上手掌', count: 6, items: [

                        {key: '✊', text: '举起拳头'},
                        {key: '👊', text: '出拳'},
                        // {key: '👍', text: '拇指向上'},
                        // {key: '👎', text: '拇指向下'},
                        {key: '👍', text: '强'},
                        {key: '👎', text: '弱'},
                        {key: '🤛', text: '朝左的拳头'},
                        {key: '🤜', text: '朝右的拳头'},
                    ],
                }, {
                    key: '🤝', name: '双手', count: 6, items: [

                        {key: '👏', text: '鼓掌'},
                        {key: '👐', text: '张开双手'},
                        {key: '🙌', text: '举双手'},
                        {key: '🙏', text: '双手合十'},
                        {key: '🤝', text: '握手'},
                        {key: '🤲', text: '掌心向上托起'},
                    ],
                }, {
                    key: '✍️', name: '动作手', count: 3, items: [

                        {key: '✍', text: '写字'},
                        {key: '💅', text: '涂指甲油'},
                        {key: '🤳', text: '自拍'},
                    ],
                }, {
                    key: '👃', name: '部分身体', count: 17, items: [

                        {key: '👀', text: '双眼'},
                        {key: '👁', text: '眼睛'},
                        {key: '👂', text: '耳朵'},
                        {key: '👃', text: '鼻子'},
                        {key: '👄', text: '嘴'},
                        {key: '👅', text: '舌头'},
                        {key: '💪', text: '肌肉'},
                        {key: '🦴', text: '骨头'},
                        {key: '🦵', text: '腿'},
                        {key: '🦶', text: '脚'},
                        {key: '🦷', text: '牙齿'},
                        {key: '🦻', text: '戴助听器的耳朵'},
                        {key: '🦾', text: '机械手臂'},
                        {key: '🦿', text: '机械腿'},
                        {key: '🧠', text: '脑'},
                        {key: '🫀', text: '心脏器官'},
                        {key: '🫁', text: '肺'},
                    ],
                }, {
                    key: '👦', name: '人物', count: 26, items: [

                        {key: '👦', text: '男孩'},
                        {key: '👧', text: '女孩'},
                        {key: '👨', text: '男人'},
                        {key: '👨🦰', text: '男人: 红发'},
                        {key: '👨🦱', text: '男人: 卷发'},
                        {key: '👨🦲', text: '男人: 秃顶'},
                        {key: '👨🦳', text: '男人: 白发'},
                        {key: '👩', text: '女人'},
                        {key: '👩🦰', text: '女人: 红发'},
                        {key: '👩🦱', text: '女人: 卷发'},
                        {key: '👩🦲', text: '女人: 秃顶'},
                        {key: '👩🦳', text: '女人: 白发'},
                        {key: '👱', text: '金色头发的人'},
                        {key: '👱♀', text: '金发女'},
                        {key: '👱♂', text: '金发男'},
                        {key: '👴', text: '老爷爷'},
                        {key: '👵', text: '老奶奶'},
                        {key: '👶', text: '小宝贝'},
                        {key: '🧑', text: '成人'},
                        {key: '🧑🦰', text: '成人: 红发'},
                        {key: '🧑🦱', text: '成人: 卷发'},
                        {key: '🧑🦲', text: '成人: 秃顶'},
                        {key: '🧑🦳', text: '成人: 白发'},
                        {key: '🧒', text: '儿童'},
                        {key: '🧓', text: '老年人'},
                        {key: '🧔', text: '有胡子的人'},
                    ],
                }, {
                    key: '🙋', name: '人物手势', count: 30, items: [

                        {key: '💁', text: '前台'},
                        {key: '💁♀', text: '前台女'},
                        {key: '💁♂', text: '前台男'},
                        {key: '🙅', text: '禁止手势'},
                        {key: '🙅♀', text: '禁止手势女'},
                        {key: '🙅♂', text: '禁止手势男'},
                        {key: '🙆', text: 'OK手势'},
                        {key: '🙆♀', text: 'OK手势女'},
                        {key: '🙆♂', text: 'OK手势男'},
                        {key: '🙇', text: '鞠躬'},
                        {key: '🙇♀', text: '女生鞠躬'},
                        {key: '🙇♂', text: '男生鞠躬'},
                        {key: '🙋', text: '举手'},
                        {key: '🙋♀', text: '女生举手'},
                        {key: '🙋♂', text: '男生举手'},
                        {key: '🙍', text: '皱眉'},
                        {key: '🙍♀', text: '皱眉女'},
                        {key: '🙍♂', text: '皱眉男'},
                        {key: '🙎', text: '撅嘴'},
                        {key: '🙎♀', text: '撅嘴女'},
                        {key: '🙎♂', text: '撅嘴男'},
                        {key: '🤦', text: '捂脸'},
                        {key: '🤦♀', text: '女生捂脸'},
                        {key: '🤦♂', text: '男生捂脸'},
                        {key: '🤷', text: '耸肩'},
                        {key: '🤷♀', text: '女生耸肩'},
                        {key: '🤷♂', text: '男生耸肩'},
                        {key: '🧏', text: '失聪者'},
                        {key: '🧏♀', text: '失聪的女人'},
                        {key: '🧏♂', text: '失聪的男人'},
                    ],
                }, {
                    key: '👨🍳', name: '人物角色', count: 79, items: [

                        {key: '👨⚕', text: '男医生'},
                        {key: '👨⚖', text: '男法官'},
                        {key: '👨✈', text: '男飞行员'},
                        {key: '👨🌾', text: '农夫'},
                        {key: '👨🍳', text: '男厨师'},
                        {key: '👨🍼', text: '哺乳的男人'},
                        {key: '👨🎓', text: '男学生'},
                        {key: '👨🎤', text: '男歌手'},
                        {key: '👨🎨', text: '男艺术家'},
                        {key: '👨🏫', text: '男老师'},
                        {key: '👨🏭', text: '男工人'},
                        {key: '👨💻', text: '男程序员'},
                        {key: '👨💼', text: '男白领'},
                        {key: '👨🔧', text: '男技工'},
                        {key: '👨🔬', text: '男科学家'},
                        {key: '👨🚀', text: '男宇航员'},
                        {key: '👨🚒', text: '男消防员'},
                        {key: '👩⚕', text: '女医生'},
                        {key: '👩⚖', text: '女法官'},
                        {key: '👩✈', text: '女飞行员'},
                        {key: '👩🌾', text: '农妇'},
                        {key: '👩🍳', text: '女厨师'},
                        {key: '👩🍼', text: '哺乳的女人'},
                        {key: '👩🎓', text: '女学生'},
                        {key: '👩🎤', text: '女歌手'},
                        {key: '👩🎨', text: '女艺术家'},
                        {key: '👩🏫', text: '女老师'},
                        {key: '👩🏭', text: '女工人'},
                        {key: '👩💻', text: '女程序员'},
                        {key: '👩💼', text: '女白领'},
                        {key: '👩🔧', text: '女技工'},
                        {key: '👩🔬', text: '女科学家'},
                        {key: '👩🚀', text: '女宇航员'},
                        {key: '👩🚒', text: '女消防员'},
                        {key: '👮', text: '警察'},
                        {key: '👮♀', text: '女警察'},
                        {key: '👮♂', text: '男警察'},
                        {key: '👰', text: '戴头纱的人'},
                        {key: '👰♀', text: '戴头纱的女人'},
                        {key: '👰♂', text: '戴头纱的男人'},
                        {key: '👲', text: '戴瓜皮帽的人'},
                        {key: '👳', text: '戴头巾的人'},
                        {key: '👳♀', text: '戴头巾的女人'},
                        {key: '👳♂', text: '戴头巾的男人'},
                        {key: '👷', text: '建筑工人'},
                        {key: '👷♀', text: '女建筑工人'},
                        {key: '👷♂', text: '男建筑工人'},
                        {key: '👸', text: '公主'},
                        {key: '💂', text: '卫兵'},
                        {key: '💂♀', text: '女卫兵'},
                        {key: '💂♂', text: '男卫兵'},
                        {key: '🕵', text: '侦探'},
                        {key: '🕵♀', text: '女侦探'},
                        {key: '🕵♂', text: '男侦探'},
                        {key: '🤰', text: '孕妇'},
                        {key: '🤱', text: '母乳喂养'},
                        {key: '🤴', text: '王子'},
                        {key: '🤵', text: '穿燕尾服的男人'},
                        {key: '🤵♀', text: '穿礼服的女人'},
                        {key: '🤵♂', text: '穿礼服的男人'},
                        {key: '🥷', text: '忍者'},
                        {key: '🧑⚕', text: '卫生工作者'},
                        {key: '🧑⚖', text: '法官'},
                        {key: '🧑✈', text: '飞行员'},
                        {key: '🧑🌾', text: '农民'},
                        {key: '🧑🍳', text: '厨师'},
                        {key: '🧑🍼', text: '哺乳的人'},
                        {key: '🧑🎓', text: '学生'},
                        {key: '🧑🎤', text: '歌手'},
                        {key: '🧑🎨', text: '艺术家'},
                        {key: '🧑🏫', text: '老师'},
                        {key: '🧑🏭', text: '工人'},
                        {key: '🧑💻', text: '程序员'},
                        {key: '🧑💼', text: '白领'},
                        {key: '🧑🔧', text: '技工'},
                        {key: '🧑🔬', text: '科学家'},
                        {key: '🧑🚀', text: '宇航员'},
                        {key: '🧑🚒', text: '消防员'},
                        {key: '🧕', text: '带头饰的女人'},
                    ],
                }, {
                    key: '🎅', name: '虚构人物', count: 31, items: [

                        {key: '🎅', text: '圣诞老人'},
                        {key: '👼', text: '小天使'},
                        {key: '🤶', text: '圣诞奶奶'},
                        {key: '🦸', text: '超级英雄'},
                        {key: '🦸♀', text: '女超级英雄'},
                        {key: '🦸♂', text: '男超级英雄'},
                        {key: '🦹', text: '超级大坏蛋'},
                        {key: '🦹♀', text: '女超级大坏蛋'},
                        {key: '🦹♂', text: '男超级大坏蛋'},
                        {key: '🧑🎄', text: '圣诞人'},
                        {key: '🧙', text: '法师'},
                        {key: '🧙♀', text: '女法师'},
                        {key: '🧙♂', text: '男法师'},
                        {key: '🧚', text: '精灵'},
                        {key: '🧚♀', text: '仙女'},
                        {key: '🧚♂', text: '仙人'},
                        {key: '🧛', text: '吸血鬼'},
                        {key: '🧛♀', text: '女吸血鬼'},
                        {key: '🧛♂', text: '男吸血鬼'},
                        {key: '🧜', text: '人鱼'},
                        {key: '🧜♀', text: '美人鱼'},
                        {key: '🧜♂', text: '男人鱼'},
                        {key: '🧝', text: '小精灵'},
                        {key: '🧝♀', text: '女小精灵'},
                        {key: '🧝♂', text: '男小精灵'},
                        {key: '🧞', text: '妖怪'},
                        {key: '🧞♀', text: '女妖怪'},
                        {key: '🧞♂', text: '男妖怪'},
                        {key: '🧟', text: '僵尸'},
                        {key: '🧟♀', text: '女僵尸'},
                        {key: '🧟♂', text: '男僵尸'},
                    ],
                }, {
                    key: '🏃', name: '人物活动', count: 39, items: [

                        {key: '🏃', text: '跑步者'},
                        {key: '🏃♀', text: '女生跑步'},
                        {key: '🏃♂', text: '男生跑步'},
                        {key: '👨🦯', text: '拄盲杖的男人'},
                        {key: '👨🦼', text: '坐电动轮椅的男人'},
                        {key: '👨🦽', text: '坐手动轮椅的男人'},
                        {key: '👩🦯', text: '拄盲杖的女人'},
                        {key: '👩🦼', text: '坐电动轮椅的女人'},
                        {key: '👩🦽', text: '坐手动轮椅的女人'},
                        {key: '👯', text: '戴兔耳朵的人'},
                        {key: '👯♀', text: '兔女郎'},
                        {key: '👯♂', text: '兔先生'},
                        {key: '💃', text: '跳舞的女人'},
                        {key: '💆', text: '按摩'},
                        {key: '💆♀', text: '女生按摩'},
                        {key: '💆♂', text: '男生按摩'},
                        {key: '💇', text: '理发'},
                        {key: '💇♀', text: '女生理发'},
                        {key: '💇♂', text: '男生理发'},
                        {key: '🕴', text: '西装革履的人'},
                        {key: '🕺', text: '跳舞的男人'},
                        {key: '🚶', text: '行人'},
                        {key: '🚶♀', text: '女行人'},
                        {key: '🚶♂', text: '男行人'},
                        {key: '🧍', text: '站立者'},
                        {key: '🧍♀', text: '站立的女人'},
                        {key: '🧍♂', text: '站立的男人'},
                        {key: '🧎', text: '下跪者'},
                        {key: '🧎♀', text: '跪下的女人'},
                        {key: '🧎♂', text: '跪下的男人'},
                        {key: '🧑🦯', text: '拄盲杖的人'},
                        {key: '🧑🦼', text: '坐电动轮椅的人'},
                        {key: '🧑🦽', text: '坐手动轮椅的人'},
                        {key: '🧖', text: '蒸房里的人'},
                        {key: '🧖♀', text: '蒸房里的女人'},
                        {key: '🧖♂', text: '蒸房里的男人'},
                        {key: '🧗', text: '攀爬的人'},
                        {key: '🧗♀', text: '攀爬的女人'},
                        {key: '🧗♂', text: '攀爬的男人'},
                    ],
                }, {
                    key: '🚴', name: '人物运动', count: 43, items: [

                        {key: '⛷', text: '滑雪的人'},
                        {key: '⛹', text: '玩球'},
                        {key: '⛹♀', text: '女生玩球'},
                        {key: '⛹♂', text: '男生玩球'},
                        {key: '🏂', text: '滑雪板'},
                        {key: '🏄', text: '冲浪'},
                        {key: '🏄♀', text: '女生冲浪'},
                        {key: '🏄♂', text: '男生冲浪'},
                        {key: '🏇', text: '赛马'},
                        {key: '🏊', text: '游泳'},
                        {key: '🏊♀', text: '女生游泳'},
                        {key: '🏊♂', text: '男生游泳'},
                        {key: '🏋', text: '举重'},
                        {key: '🏋♀', text: '女生举重'},
                        {key: '🏋♂', text: '男生举重'},
                        {key: '🏌', text: '打高尔夫的人'},
                        {key: '🏌♀', text: '女生打高尔夫'},
                        {key: '🏌♂', text: '男生打高尔夫'},
                        {key: '🚣', text: '划艇'},
                        {key: '🚣♀', text: '女生划船'},
                        {key: '🚣♂', text: '男生划船'},
                        {key: '🚴', text: '骑自行车'},
                        {key: '🚴♀', text: '女生骑自行车'},
                        {key: '🚴♂', text: '男生骑自行车'},
                        {key: '🚵', text: '骑山地车'},
                        {key: '🚵♀', text: '女生骑山地车'},
                        {key: '🚵♂', text: '男生骑山地车'},
                        {key: '🤸', text: '侧手翻'},
                        {key: '🤸♀', text: '女生侧手翻'},
                        {key: '🤸♂', text: '男生侧手翻'},
                        {key: '🤹', text: '抛接杂耍'},
                        {key: '🤹♀', text: '女生抛接杂耍'},
                        {key: '🤹♂', text: '男生抛接杂耍'},
                        {key: '🤺', text: '击剑选手'},
                        {key: '🤼', text: '摔跤选手'},
                        {key: '🤼♀', text: '女生摔跤'},
                        {key: '🤼♂', text: '男生摔跤'},
                        {key: '🤽', text: '水球'},
                        {key: '🤽♀', text: '女生玩水球'},
                        {key: '🤽♂', text: '男生玩水球'},
                        {key: '🤾', text: '手球'},
                        {key: '🤾♀', text: '女生玩手球'},
                        {key: '🤾♂', text: '男生玩手球'},
                    ],
                }, {
                    key: '🛌', name: '人物休息', count: 5, items: [

                        {key: '🛀', text: '洗澡的人'},
                        {key: '🛌', text: '躺在床上的人'},
                        {key: '🧘', text: '盘腿的人'},
                        {key: '🧘♀', text: '盘腿的女人'},
                        {key: '🧘♂', text: '盘腿的男人'},
                    ],
                }, {
                    key: '👨👩👧👦', name: '家庭', count: 38, items: [

                        {key: '👨❤👨', text: '情侣: 男人男人'},
                        {key: '👨❤💋👨', text: '亲吻:男人男人'},
                        {key: '👨👦', text: '家庭: 男人男孩'},
                        {key: '👨👦👦', text: '家庭: 男人男孩男孩'},
                        {key: '👨👧', text: '家庭: 男人女孩'},
                        {key: '👨👧👦', text: '家庭: 男人女孩男孩'},
                        {key: '👨👧👧', text: '家庭: 男人女孩女孩'},
                        {key: '👨👨👦', text: '家庭: 男人男人男孩'},
                        {key: '👨👨👦👦', text: '家庭:男人男人男孩男孩'},
                        {key: '👨👨👧', text: '家庭: 男人男人女孩'},
                        {key: '👨👨👧👦', text: '家庭:男人男人女孩男孩'},
                        {key: '👨👨👧👧', text: '家庭:男人男人女孩女孩'},
                        {key: '👨👩👦', text: '家庭: 男人女人男孩'},
                        {key: '👨👩👦👦', text: '家庭:男人女人男孩男孩'},
                        {key: '👨👩👧', text: '家庭: 男人女人女孩'},
                        {key: '👨👩👧👦', text: '家庭:男人女人女孩男孩'},
                        {key: '👨👩👧👧', text: '家庭:男人女人女孩女孩'},
                        {key: '👩❤👨', text: '情侣: 女人男人'},
                        {key: '👩❤👩', text: '情侣: 女人女人'},
                        {key: '👩❤💋👨', text: '亲吻:女人男人'},
                        {key: '👩❤💋👩', text: '亲吻:女人女人'},
                        {key: '👩👦', text: '家庭: 女人男孩'},
                        {key: '👩👦👦', text: '家庭: 女人男孩男孩'},
                        {key: '👩👧', text: '家庭: 女人女孩'},
                        {key: '👩👧👦', text: '家庭: 女人女孩男孩'},
                        {key: '👩👧👧', text: '家庭: 女人女孩女孩'},
                        {key: '👩👩👦', text: '家庭: 女人女人男孩'},
                        {key: '👩👩👦👦', text: '家庭:女人女人男孩男孩'},
                        {key: '👩👩👧', text: '家庭: 女人女人女孩'},
                        {key: '👩👩👧👦', text: '家庭:女人女人女孩男孩'},
                        {key: '👩👩👧👧', text: '家庭:女人女人女孩女孩'},
                        {key: '👪', text: '家庭'},
                        {key: '👫', text: '手拉手的一男一女'},
                        {key: '👬', text: '手拉手的两个男人'},
                        {key: '👭', text: '手拉手的两个女人'},
                        {key: '💏', text: '亲吻'},
                        {key: '💑', text: '情侣'},
                        {key: '🧑🤝🧑', text: '手拉手的两个人'},
                    ],
                }, {
                    key: '👣', name: '人物标记', count: 5, items: [

                        {key: '👣', text: '脚印'},
                        {key: '👤', text: '人像'},
                        {key: '👥', text: '双人像'},
                        {key: '🗣', text: '说话'},
                        {key: '🫂', text: '人的拥抱'},
                    ],
                }],
            },
            {
                key: '🏼', name: '肤色和发型', count: 9, items: [{
                    key: '🏽', name: '肤色', count: 5, items: [

                        {key: '🏻', text: '较浅肤色'},
                        {key: '🏼', text: '中等-浅肤色'},
                        {key: '🏽', text: '中等肤色'},
                        {key: '🏾', text: '中等-深肤色'},
                        {key: '🏿', text: '较深肤色'},
                    ],
                }, {
                    key: '🦱', name: '发型', count: 4, items: [

                        {key: '🦰', text: '红发'},
                        {key: '🦱', text: '卷发'},
                        {key: '🦲', text: '秃顶'},
                        {key: '🦳', text: '白发'},
                    ],
                }],
            },
            {
                key: '🐵', name: '动物和自然', count: 140, items: [{
                    key: '🐀', name: '哺乳动物', count: 64, items: [

                        {key: '🐀', text: '耗子'},
                        {key: '🐁', text: '老鼠'},
                        {key: '🐂', text: '公牛'},
                        {key: '🐃', text: '水牛'},
                        {key: '🐄', text: '奶牛'},
                        {key: '🐅', text: '老虎'},
                        {key: '🐆', text: '豹子'},
                        {key: '🐇', text: '兔子'},
                        {key: '🐈', text: '猫'},
                        {key: '🐈⬛', text: '黑猫'},
                        {key: '🐎', text: '马'},
                        {key: '🐏', text: '公羊'},
                        {key: '🐐', text: '山羊'},
                        {key: '🐑', text: '母羊'},
                        {key: '🐒', text: '猴子'},
                        {key: '🐕', text: '狗'},
                        {key: '🐕🦺', text: '服务犬'},
                        {key: '🐖', text: '猪'},
                        {key: '🐗', text: '野猪'},
                        {key: '🐘', text: '大象'},
                        {key: '🐨', text: '考拉'},
                        {key: '🐩', text: '贵宾犬'},
                        {key: '🐪', text: '骆驼'},
                        {key: '🐫', text: '双峰骆驼'},
                        {key: '🐭', text: '老鼠头'},
                        {key: '🐮', text: '奶牛头'},
                        {key: '🐯', text: '老虎头'},
                        {key: '🐰', text: '兔子头'},
                        {key: '🐱', text: '猫脸'},
                        {key: '🐴', text: '马头'},
                        {key: '🐵', text: '猴头'},
                        {key: '🐶', text: '狗脸'},
                        {key: '🐷', text: '猪头'},
                        {key: '🐹', text: '仓鼠'},
                        {key: '🐺', text: '狼'},
                        {key: '🐻', text: '熊'},
                        {key: '🐻❄', text: '北极熊'},
                        {key: '🐼', text: '熊猫'},
                        {key: '🐽', text: '猪鼻子'},
                        {key: '🐾', text: '爪印'},
                        {key: '🐿', text: '松鼠'},
                        {key: '🦁', text: '狮子'},
                        {key: '🦄', text: '独角兽'},
                        {key: '🦇', text: '蝙蝠'},
                        {key: '🦊', text: '狐狸'},
                        {key: '🦌', text: '鹿'},
                        {key: '🦍', text: '大猩猩'},
                        {key: '🦏', text: '犀牛'},
                        {key: '🦒', text: '长颈鹿'},
                        {key: '🦓', text: '斑马'},
                        {key: '🦔', text: '刺猬'},
                        {key: '🦘', text: '袋鼠'},
                        {key: '🦙', text: '美洲鸵'},
                        {key: '🦛', text: '河马'},
                        {key: '🦝', text: '浣熊'},
                        {key: '🦡', text: '獾'},
                        {key: '🦣', text: '猛犸'},
                        {key: '🦥', text: '树懒'},
                        {key: '🦦', text: '水獭'},
                        {key: '🦧', text: '红毛猩猩'},
                        {key: '🦨', text: '臭鼬'},
                        {key: '🦫', text: '海狸'},
                        {key: '🦬', text: '大野牛'},
                        {key: '🦮', text: '导盲犬'},
                    ],
                }, {
                    key: '🐓', name: '鸟类', count: 18, items: [

                        {key: '🐓', text: '公鸡'},
                        {key: '🐔', text: '鸡'},
                        {key: '🐣', text: '小鸡破壳'},
                        {key: '🐤', text: '小鸡'},
                        {key: '🐥', text: '正面朝向的小鸡'},
                        {key: '🐦', text: '鸟'},
                        {key: '🐧', text: '企鹅'},
                        {key: '🕊', text: '鸽'},
                        {key: '🦃', text: '火鸡'},
                        {key: '🦅', text: '鹰'},
                        {key: '🦆', text: '鸭子'},
                        {key: '🦉', text: '猫头鹰'},
                        {key: '🦚', text: '孔雀'},
                        {key: '🦜', text: '鹦鹉'},
                        {key: '🦢', text: '天鹅'},
                        {key: '🦤', text: '渡渡鸟'},
                        {key: '🦩', text: '火烈鸟'},
                        {key: '🪶', text: '羽毛'},
                    ],
                }, {
                    key: '🐸', name: '两栖动物', count: 1, items: [

                        {key: '🐸', text: '青蛙'},
                    ],
                }, {
                    key: '🐍', name: '爬行动物', count: 8, items: [

                        {key: '🐉', text: '龙'},
                        {key: '🐊', text: '鳄鱼'},
                        {key: '🐍', text: '蛇'},
                        {key: '🐢', text: '龟'},
                        {key: '🐲', text: '龙头'},
                        {key: '🦎', text: '蜥蜴'},
                        {key: '🦕', text: '蜥蜴类'},
                        {key: '🦖', text: '霸王龙'},
                    ],
                }, {
                    key: '🐟', name: '海洋动物', count: 10, items: [

                        {key: '🐋', text: '鲸鱼'},
                        {key: '🐙', text: '章鱼'},
                        {key: '🐚', text: '海螺'},
                        {key: '🐟', text: '鱼'},
                        {key: '🐠', text: '热带鱼'},
                        {key: '🐡', text: '河豚'},
                        {key: '🐬', text: '海豚'},
                        {key: '🐳', text: '喷水的鲸'},
                        {key: '🦈', text: '鲨鱼'},
                        {key: '🦭', text: '海豹'},
                    ],
                }, {
                    key: '🐛', name: '昆虫', count: 16, items: [

                        {key: '🐌', text: '蜗牛'},
                        {key: '🐛', text: '毛毛虫'},
                        {key: '🐜', text: '蚂蚁'},
                        {key: '🐝', text: '蜜蜂'},
                        {key: '🐞', text: '瓢虫'},
                        {key: '🕷', text: '蜘蛛'},
                        {key: '🕸', text: '蜘蛛网'},
                        {key: '🦂', text: '蝎子'},
                        {key: '🦋', text: '蝴蝶'},
                        {key: '🦗', text: '蟋蟀'},
                        {key: '🦟', text: '蚊子'},
                        {key: '🦠', text: '细菌'},
                        {key: '🪰', text: '苍蝇'},
                        {key: '🪱', text: '蠕虫'},
                        {key: '🪲', text: '甲虫'},
                        {key: '🪳', text: '蟑螂'},
                    ],
                }, {
                    key: '🌹', name: '花朵', count: 10, items: [

                        {key: '🌷', text: '郁金香'},
                        {key: '🌸', text: '樱花'},
                        {key: '🌹', text: '玫瑰'},
                        {key: '🌺', text: '芙蓉'},
                        {key: '🌻', text: '向日葵'},
                        {key: '🌼', text: '开花'},
                        {key: '🏵', text: '圆形花饰'},
                        {key: '💐', text: '花束'},
                        {key: '💮', text: '白花'},
                        {key: '🥀', text: '枯萎的花'},
                    ],
                }, {
                    key: '🌴', name: '其他植物', count: 13, items: [

                        {key: '☘', text: '三叶草'},
                        {key: '🌱', text: '幼苗'},
                        {key: '🌲', text: '松树'},
                        {key: '🌳', text: '落叶树'},
                        {key: '🌴', text: '棕榈树'},
                        {key: '🌵', text: '仙人掌'},
                        {key: '🌾', text: '稻子'},
                        {key: '🌿', text: '药草'},
                        {key: '🍀', text: '四叶草'},
                        {key: '🍁', text: '枫叶'},
                        {key: '🍂', text: '落叶'},
                        {key: '🍃', text: '风吹叶落'},
                        {key: '🪴', text: '盆栽植物'},
                    ],
                }],
            },
            {
                key: '🍓', name: '食物和饮料', count: 129, items: [{
                    key: '🍅', name: '水果', count: 19, items: [

                        {key: '🍅', text: '西红柿'},
                        {key: '🍇', text: '葡萄'},
                        {key: '🍈', text: '甜瓜'},
                        {key: '🍉', text: '西瓜'},
                        {key: '🍊', text: '橘子'},
                        {key: '🍋', text: '柠檬'},
                        {key: '🍌', text: '香蕉'},
                        {key: '🍍', text: '菠萝'},
                        {key: '🍎', text: '红苹果'},
                        {key: '🍏', text: '青苹果'},
                        {key: '🍐', text: '梨'},
                        {key: '🍑', text: '桃'},
                        {key: '🍒', text: '樱桃'},
                        {key: '🍓', text: '草莓'},
                        {key: '🥝', text: '猕猴桃'},
                        {key: '🥥', text: '椰子'},
                        {key: '🥭', text: '芒果'},
                        {key: '🫐', text: '蓝莓'},
                        {key: '🫒', text: '橄榄'},
                    ],
                }, {
                    key: '🍄', name: '蔬菜', count: 15, items: [

                        {key: '🌰', text: '栗子'},
                        {key: '🌶', text: '红辣椒'},
                        {key: '🌽', text: '玉米'},
                        {key: '🍄', text: '蘑菇'},
                        {key: '🍆', text: '茄子'},
                        {key: '🥑', text: '鳄梨'},
                        {key: '🥒', text: '黄瓜'},
                        {key: '🥔', text: '土豆'},
                        {key: '🥕', text: '胡萝卜'},
                        {key: '🥜', text: '花生'},
                        {key: '🥦', text: '西兰花'},
                        {key: '🥬', text: '绿叶蔬菜'},
                        {key: '🧄', text: '蒜'},
                        {key: '🧅', text: '洋葱'},
                        {key: '🫑', text: '灯笼椒'},
                    ],
                }, {
                    key: '🍕', name: '熟食', count: 34, items: [

                        {key: '🌭', text: '热狗'},
                        {key: '🌮', text: '墨西哥卷饼'},
                        {key: '🌯', text: '墨西哥玉米煎饼'},
                        {key: '🍔', text: '汉堡'},
                        {key: '🍕', text: '披萨'},
                        {key: '🍖', text: '排骨'},
                        {key: '🍗', text: '家禽的腿'},
                        {key: '🍞', text: '面包'},
                        {key: '🍟', text: '薯条'},
                        {key: '🍲', text: '一锅食物'},
                        {key: '🍳', text: '煎蛋'},
                        {key: '🍿', text: '爆米花'},
                        {key: '🥐', text: '羊角面包'},
                        {key: '🥓', text: '培根'},
                        {key: '🥖', text: '法式长棍面包'},
                        {key: '🥗', text: '绿色沙拉'},
                        {key: '🥘', text: '装有食物的浅底锅'},
                        {key: '🥙', text: '夹心饼'},
                        {key: '🥚', text: '蛋'},
                        {key: '🥞', text: '烙饼'},
                        {key: '🥣', text: '碗勺'},
                        {key: '🥨', text: '椒盐卷饼'},
                        {key: '🥩', text: '肉块'},
                        {key: '🥪', text: '三明治'},
                        {key: '🥫', text: '罐头食品'},
                        {key: '🥯', text: '面包圈'},
                        {key: '🧀', text: '芝士'},
                        {key: '🧂', text: '盐'},
                        {key: '🧆', text: '炸豆丸子'},
                        {key: '🧇', text: '华夫饼'},
                        {key: '🧈', text: '黄油'},
                        {key: '🫓', text: '扁面包'},
                        {key: '🫔', text: '墨西哥粽子'},
                        {key: '🫕', text: '奶酪火锅'},
                    ],
                }, {
                    key: '🍚', name: '亚洲食物', count: 17, items: [

                        {key: '🍘', text: '米饼'},
                        {key: '🍙', text: '饭团'},
                        {key: '🍚', text: '米饭'},
                        {key: '🍛', text: '咖喱饭'},
                        {key: '🍜', text: '面条'},
                        {key: '🍝', text: '意粉'},
                        {key: '🍠', text: '烤红薯'},
                        {key: '🍡', text: '团子'},
                        {key: '🍢', text: '关东煮'},
                        {key: '🍣', text: '寿司'},
                        {key: '🍤', text: '天妇罗'},
                        {key: '🍥', text: '鱼板'},
                        {key: '🍱', text: '盒饭'},
                        {key: '🥟', text: '饺子'},
                        {key: '🥠', text: '幸运饼干'},
                        {key: '🥡', text: '外卖盒'},
                        {key: '🥮', text: '月饼'},
                    ],
                }, {
                    key: '🦀', name: '海产', count: 5, items: [

                        {key: '🦀', text: '蟹'},
                        {key: '🦐', text: '虾'},
                        {key: '🦑', text: '乌贼'},
                        {key: '🦞', text: '龙虾'},
                        {key: '🦪', text: '牡蛎'},
                    ],
                }, {
                    key: '🍦', name: '甜食', count: 14, items: [

                        {key: '🍦', text: '圆筒冰激凌'},
                        {key: '🍧', text: '刨冰'},
                        {key: '🍨', text: '冰淇淋'},
                        {key: '🍩', text: '甜甜圈'},
                        {key: '🍪', text: '饼干'},
                        {key: '🍫', text: '巧克力'},
                        {key: '🍬', text: '糖'},
                        {key: '🍭', text: '棒棒糖'},
                        {key: '🍮', text: '奶黄'},
                        {key: '🍯', text: '蜂蜜'},
                        {key: '🍰', text: '水果蛋糕'},
                        {key: '🎂', text: '生日蛋糕'},
                        {key: '🥧', text: '派'},
                        {key: '🧁', text: '纸杯蛋糕'},
                    ],
                }, {
                    key: '☕', name: '饮料', count: 19, items: [

                        {key: '☕', text: '热饮'},
                        {key: '🍵', text: '热茶'},
                        {key: '🍶', text: '清酒'},
                        {key: '🍷', text: '葡萄酒'},
                        {key: '🍸', text: '鸡尾酒'},
                        {key: '🍹', text: '热带水果饮料'},
                        {key: '🍺', text: '啤酒'},
                        {key: '🍻', text: '干杯'},
                        {key: '🍼', text: '奶瓶'},
                        {key: '🍾', text: '开香槟'},
                        {key: '🥂', text: '碰杯'},
                        {key: '🥃', text: '平底杯'},
                        {key: '🥛', text: '一杯奶'},
                        {key: '🥤', text: '带吸管杯'},
                        {key: '🧃', text: '饮料盒'},
                        {key: '🧉', text: '马黛茶'},
                        {key: '🧊', text: '冰块'},
                        {key: '🧋', text: '珍珠奶茶'},
                        {key: '🫖', text: '茶壶'},
                    ],
                }, {
                    key: '🍴', name: '餐具', count: 6, items: [

                        {key: '🍴', text: '刀叉'},
                        {key: '🍽', text: '餐具'},
                        {key: '🏺', text: '双耳瓶'},
                        {key: '🔪', text: '菜刀'},
                        {key: '🥄', text: '匙'},
                        {key: '🥢', text: '筷子'},
                    ],
                }],
            },
            {
                key: '🚌', name: '旅行和地点', count: 215, items: [{
                    key: '🌍', name: '地图', count: 7, items: [

                        {key: '🌍', text: '地球上的欧洲非洲'},
                        {key: '🌎', text: '地球上的美洲'},
                        {key: '🌏', text: '地球上的亚洲澳洲'},
                        {key: '🌐', text: '带经纬线的地球'},
                        {key: '🗺', text: '世界地图'},
                        {key: '🗾', text: '日本地图'},
                        {key: '🧭', text: '指南针'},
                    ],
                }, {
                    key: '🌋', name: '地理', count: 9, items: [

                        {key: '⛰', text: '山'},
                        {key: '🌋', text: '火山'},
                        {key: '🏔', text: '雪山'},
                        {key: '🏕', text: '露营'},
                        {key: '🏖', text: '沙滩伞'},
                        {key: '🏜', text: '沙漠'},
                        {key: '🏝', text: '无人荒岛'},
                        {key: '🏞', text: '国家公园'},
                        {key: '🗻', text: '富士山'},
                    ],
                }, {
                    key: '🏗️', name: '建筑', count: 27, items: [

                        {key: '🏗', text: '施工'},
                        {key: '🏘', text: '房屋建筑'},
                        {key: '🏚', text: '废墟'},
                        {key: '🏛', text: '古典建筑'},
                        {key: '🏟', text: '体育馆'},
                        {key: '🏠', text: '房子'},
                        {key: '🏡', text: '别墅'},
                        {key: '🏢', text: '办公楼'},
                        {key: '🏣', text: '日本邮局'},
                        {key: '🏤', text: '邮局'},
                        {key: '🏥', text: '医院'},
                        {key: '🏦', text: '银行'},
                        {key: '🏨', text: '酒店'},
                        {key: '🏩', text: '情人酒店'},
                        {key: '🏪', text: '便利店'},
                        {key: '🏫', text: '学校'},
                        {key: '🏬', text: '商场'},
                        {key: '🏭', text: '工厂'},
                        {key: '🏯', text: '日本城堡'},
                        {key: '🏰', text: '欧洲城堡'},
                        {key: '💒', text: '婚礼'},
                        {key: '🗼', text: '东京塔'},
                        {key: '🗽', text: '自由女神像'},
                        {key: '🛖', text: '小屋'},
                        {key: '🧱', text: '砖'},
                        {key: '🪨', text: '岩石'},
                        {key: '🪵', text: '木头'},
                    ],
                }, {
                    key: '⛪', name: '宗教场所', count: 6, items: [

                        {key: '⛩', text: '神社'},
                        {key: '⛪', text: '教堂'},
                        {key: '🕋', text: '克尔白'},
                        {key: '🕌', text: '清真寺'},
                        {key: '🕍', text: '犹太教堂'},
                        {key: '🛕', text: '印度寺庙'},
                    ],
                }, {
                    key: '⛲', name: '其他场所', count: 16, items: [

                        {key: '♨', text: '温泉'},
                        {key: '⛲', text: '喷泉'},
                        {key: '⛺', text: '帐篷'},
                        {key: '🌁', text: '有雾'},
                        {key: '🌃', text: '夜晚'},
                        {key: '🌄', text: '山顶日出'},
                        {key: '🌅', text: '日出'},
                        {key: '🌆', text: '城市黄昏'},
                        {key: '🌇', text: '日落'},
                        {key: '🌉', text: '夜幕下的桥'},
                        {key: '🎠', text: '旋转木马'},
                        {key: '🎡', text: '摩天轮'},
                        {key: '🎢', text: '过山车'},
                        {key: '🎪', text: '马戏团帐篷'},
                        {key: '🏙', text: '城市风光'},
                        {key: '💈', text: '理发店'},
                    ],
                }, {
                    key: '⛽', name: '陆路交通', count: 49, items: [

                        {key: '⛽', text: '油泵'},
                        {key: '🏍', text: '摩托车'},
                        {key: '🏎', text: '赛车'},
                        {key: '🚂', text: '蒸汽火车'},
                        {key: '🚃', text: '轨道车'},
                        {key: '🚄', text: '高速列车'},
                        {key: '🚅', text: '子弹头高速列车'},
                        {key: '🚆', text: '火车'},
                        {key: '🚇', text: '地铁'},
                        {key: '🚈', text: '轻轨'},
                        {key: '🚉', text: '车站'},
                        {key: '🚊', text: '路面电车'},
                        {key: '🚋', text: '有轨电车'},
                        {key: '🚌', text: '公交车'},
                        {key: '🚍', text: '迎面驶来的公交车'},
                        {key: '🚎', text: '无轨电车'},
                        {key: '🚏', text: '公交车站'},
                        {key: '🚐', text: '小巴'},
                        {key: '🚑', text: '救护车'},
                        {key: '🚒', text: '消防车'},
                        {key: '🚓', text: '警车'},
                        {key: '🚔', text: '迎面驶来的警车'},
                        {key: '🚕', text: '出租车'},
                        {key: '🚖', text: '迎面驶来的出租车'},
                        {key: '🚗', text: '汽车'},
                        {key: '🚘', text: '迎面驶来的汽车'},
                        {key: '🚙', text: '运动型多用途车'},
                        {key: '🚚', text: '货车'},
                        {key: '🚛', text: '铰接式货车'},
                        {key: '🚜', text: '拖拉机'},
                        {key: '🚝', text: '单轨'},
                        {key: '🚞', text: '山区铁路'},
                        {key: '🚥', text: '横向的红绿灯'},
                        {key: '🚦', text: '纵向的红绿灯'},
                        {key: '🚧', text: '路障'},
                        {key: '🚨', text: '警车灯'},
                        {key: '🚲', text: '自行车'},
                        {key: '🛑', text: '停止标志'},
                        {key: '🛢', text: '石油桶'},
                        {key: '🛣', text: '高速公路'},
                        {key: '🛤', text: '铁轨'},
                        {key: '🛴', text: '滑板车'},
                        {key: '🛵', text: '小型摩托车'},
                        {key: '🛹', text: '滑板'},
                        {key: '🛺', text: '三轮摩托车'},
                        {key: '🛻', text: '敞蓬小型载货卡车'},
                        {key: '🛼', text: '四轮滑冰鞋'},
                        {key: '🦼', text: '电动轮椅'},
                        {key: '🦽', text: '手动轮椅'},
                    ],
                }, {
                    key: '🚢', name: '水路交通', count: 8, items: [

                        {key: '⚓', text: '锚'},
                        {key: '⛴', text: '渡轮'},
                        {key: '⛵', text: '帆船'},
                        {key: '🚢', text: '船'},
                        {key: '🚤', text: '快艇'},
                        {key: '🛥', text: '摩托艇'},
                        {key: '🛳', text: '客轮'},
                        {key: '🛶', text: '独木舟'},
                    ],
                }, {
                    key: '✈️', name: '空中运输', count: 13, items: [

                        {key: '✈', text: '飞机'},
                        {key: '💺', text: '座位'},
                        {key: '🚀', text: '火箭'},
                        {key: '🚁', text: '直升机'},
                        {key: '🚟', text: '空轨'},
                        {key: '🚠', text: '缆车'},
                        {key: '🚡', text: '索道'},
                        {key: '🛩', text: '小型飞机'},
                        {key: '🛫', text: '航班起飞'},
                        {key: '🛬', text: '航班降落'},
                        {key: '🛰', text: '卫星'},
                        {key: '🛸', text: '飞碟'},
                        {key: '🪂', text: '降落伞'},
                    ],
                }, {
                    key: '🛎️', name: '酒店', count: 2, items: [

                        {key: '🛎', text: '服务铃'},
                        {key: '🧳', text: '行李箱'},
                    ],
                }, {
                    key: '⌚', name: '时间', count: 31, items: [

                        {key: '⌚', text: '手表'},
                        {key: '⌛', text: '沙漏'},
                        {key: '⏰', text: '闹钟'},
                        {key: '⏱', text: '秒表'},
                        {key: '⏲', text: '定时器'},
                        {key: '⏳', text: '沙正往下流的沙漏'},
                        {key: '🕐', text: '一点'},
                        {key: '🕑', text: '两点'},
                        {key: '🕒', text: '三点'},
                        {key: '🕓', text: '四点'},
                        {key: '🕔', text: '五点'},
                        {key: '🕕', text: '六点'},
                        {key: '🕖', text: '七点'},
                        {key: '🕗', text: '八点'},
                        {key: '🕘', text: '九点'},
                        {key: '🕙', text: '十点'},
                        {key: '🕚', text: '十一点'},
                        {key: '🕛', text: '十二点'},
                        {key: '🕜', text: '一点半'},
                        {key: '🕝', text: '两点半'},
                        {key: '🕞', text: '三点半'},
                        {key: '🕟', text: '四点半'},
                        {key: '🕠', text: '五点半'},
                        {key: '🕡', text: '六点半'},
                        {key: '🕢', text: '七点半'},
                        {key: '🕣', text: '八点半'},
                        {key: '🕤', text: '九点半'},
                        {key: '🕥', text: '十点半'},
                        {key: '🕦', text: '十一点半'},
                        {key: '🕧', text: '十二点半'},
                        {key: '🕰', text: '座钟'},
                    ],
                }, {
                    key: '☂️', name: '天空&amp;天气', count: 47, items: [

                        {key: '☀', text: '太阳'},
                        {key: '☁', text: '云'},
                        {key: '☂', text: '伞'},
                        {key: '☃', text: '雪与雪人'},
                        {key: '☄', text: '彗星'},
                        {key: '☔', text: '雨伞'},
                        {key: '⚡', text: '高压'},
                        {key: '⛄', text: '雪人'},
                        {key: '⛅', text: '阴'},
                        {key: '⛈', text: '雷阵雨'},
                        {key: '⛱', text: '阳伞'},
                        {key: '❄', text: '雪花'},
                        {key: '⭐', text: '星星'},
                        {key: '🌀', text: '台风'},
                        {key: '🌂', text: '收起的伞'},
                        {key: '🌈', text: '彩虹'},
                        {key: '🌊', text: '浪花'},
                        {key: '🌌', text: '银河'},
                        {key: '🌑', text: '朔月'},
                        {key: '🌒', text: '娥眉月'},
                        {key: '🌓', text: '上弦月'},
                        {key: '🌔', text: '盈凸月'},
                        {key: '🌕', text: '满月'},
                        {key: '🌖', text: '亏凸月'},
                        {key: '🌗', text: '下弦月'},
                        {key: '🌘', text: '残月'},
                        {key: '🌙', text: '弯月'},
                        {key: '🌚', text: '微笑的朔月'},
                        {key: '🌛', text: '微笑的上弦月'},
                        {key: '🌜', text: '微笑的下弦月'},
                        {key: '🌝', text: '微笑的月亮'},
                        {key: '🌞', text: '微笑的太阳'},
                        {key: '🌟', text: '闪亮的星星'},
                        {key: '🌠', text: '流星'},
                        {key: '🌡', text: '温度计'},
                        {key: '🌤', text: '晴偶有云'},
                        {key: '🌥', text: '多云'},
                        {key: '🌦', text: '晴转雨'},
                        {key: '🌧', text: '下雨'},
                        {key: '🌨', text: '下雪'},
                        {key: '🌩', text: '打雷'},
                        {key: '🌪', text: '龙卷风'},
                        {key: '🌫', text: '雾'},
                        {key: '🌬', text: '大风'},
                        {key: '💧', text: '水滴'},
                        {key: '🔥', text: '火焰'},
                        {key: '🪐', text: '有环行星'},
                    ],
                }],
            },
            {
                key: '⚽', name: '活动', count: 84, items: [{
                    key: '🎈', name: '事件', count: 21, items: [

                        {key: '✨', text: '闪亮'},
                        {key: '🎀', text: '蝴蝶结'},
                        {key: '🎁', text: '礼物'},
                        {key: '🎃', text: '南瓜灯'},
                        {key: '🎄', text: '圣诞树'},
                        {key: '🎆', text: '焰火'},
                        {key: '🎇', text: '烟花'},
                        {key: '🎈', text: '气球'},
                        {key: '🎉', text: '拉炮彩带'},
                        {key: '🎊', text: '五彩纸屑球'},
                        {key: '🎋', text: '七夕树'},
                        {key: '🎍', text: '门松'},
                        {key: '🎎', text: '日本人形'},
                        {key: '🎏', text: '鲤鱼旗'},
                        {key: '🎐', text: '风铃'},
                        {key: '🎑', text: '赏月'},
                        {key: '🎗', text: '提示丝带'},
                        {key: '🎟', text: '入场券'},
                        {key: '🎫', text: '票'},
                        {key: '🧧', text: '红包'},
                        {key: '🧨', text: '爆竹'},
                    ],
                }, {
                    key: '🏅', name: '奖牌', count: 6, items: [

                        {key: '🎖', text: '军功章'},
                        {key: '🏅', text: '奖牌'},
                        {key: '🏆', text: '奖杯'},
                        {key: '🥇', text: '金牌'},
                        {key: '🥈', text: '银牌'},
                        {key: '🥉', text: '铜牌'},
                    ],
                }, {
                    key: '🏀', name: '运动', count: 27, items: [

                        {key: '⚽', text: '足球'},
                        {key: '⚾', text: '棒球'},
                        {key: '⛳', text: '高尔夫球洞'},
                        {key: '⛸', text: '滑冰'},
                        {key: '🎣', text: '钓鱼竿'},
                        {key: '🎳', text: '保龄球'},
                        {key: '🎽', text: '运动背心'},
                        {key: '🎾', text: '网球'},
                        {key: '🎿', text: '滑雪'},
                        {key: '🏀', text: '篮球'},
                        {key: '🏈', text: '美式橄榄球'},
                        {key: '🏉', text: '英式橄榄球'},
                        {key: '🏏', text: '板球'},
                        {key: '🏐', text: '排球'},
                        {key: '🏑', text: '曲棍球'},
                        {key: '🏒', text: '冰球'},
                        {key: '🏓', text: '乒乓球'},
                        {key: '🏸', text: '羽毛球'},
                        {key: '🛷', text: '雪橇'},
                        {key: '🤿', text: '潜水面罩'},
                        {key: '🥅', text: '球门'},
                        {key: '🥊', text: '拳击手套'},
                        {key: '🥋', text: '练武服'},
                        {key: '🥌', text: '冰壶'},
                        {key: '🥍', text: '袋棍球'},
                        {key: '🥎', text: '垒球'},
                        {key: '🥏', text: '飞盘'},
                    ],
                }, {
                    key: '🎯', name: '游戏', count: 23, items: [

                        {key: '♟', text: '兵'},
                        {key: '♠', text: '黑桃'},
                        {key: '♣', text: '梅花'},
                        {key: '♥', text: '红桃'},
                        {key: '♦', text: '方片'},
                        {key: '🀄', text: '红中'},
                        {key: '🃏', text: '大小王'},
                        {key: '🎮', text: '游戏手柄'},
                        {key: '🎯', text: '正中靶心的飞镖'},
                        {key: '🎰', text: '老虎机'},
                        {key: '🎱', text: '台球'},
                        {key: '🎲', text: '骰子'},
                        {key: '🎴', text: '花札'},
                        {key: '🔮', text: '水晶球'},
                        {key: '🕹', text: '游戏操控杆'},
                        {key: '🧩', text: '拼图'},
                        {key: '🧸', text: '泰迪熊'},
                        {key: '🧿', text: '纳扎尔护身符'},
                        {key: '🪀', text: '悠悠球'},
                        {key: '🪁', text: '风筝'},
                        {key: '🪄', text: '魔棒'},
                        {key: '🪅', text: '彩罐'},
                        {key: '🪆', text: '套娃'},
                    ],
                }, {
                    key: '🎨', name: '艺术&amp;工艺', count: 7, items: [

                        {key: '🎨', text: '调色盘'},
                        {key: '🎭', text: '面具'},
                        {key: '🖼', text: '带框的画'},
                        {key: '🧵', text: '线'},
                        {key: '🧶', text: '毛线'},
                        {key: '🪡', text: '缝合针'},
                        {key: '🪢', text: '结'},
                    ],
                }],
            },
            {
                key: '⌚', name: '物品', count: 250, items: [{
                    key: '👖', name: '服饰', count: 45, items: [

                        {key: '⛑', text: '白十字头盔'},
                        {key: '🎒', text: '书包'},
                        {key: '🎓', text: '毕业帽'},
                        {key: '🎩', text: '礼帽'},
                        {key: '👑', text: '皇冠'},
                        {key: '👒', text: '女帽'},
                        {key: '👓', text: '眼镜'},
                        {key: '👔', text: '领带'},
                        {key: '👕', text: 'T恤'},
                        {key: '👖', text: '牛仔裤'},
                        {key: '👗', text: '连衣裙'},
                        {key: '👘', text: '和服'},
                        {key: '👙', text: '比基尼'},
                        {key: '👚', text: '女装'},
                        {key: '👛', text: '钱包'},
                        {key: '👜', text: '手提包'},
                        {key: '👝', text: '手袋'},
                        {key: '👞', text: '男鞋'},
                        {key: '👟', text: '跑鞋'},
                        {key: '👠', text: '高跟鞋'},
                        {key: '👡', text: '女式凉鞋'},
                        {key: '👢', text: '女靴'},
                        {key: '💄', text: '唇膏'},
                        {key: '💍', text: '戒指'},
                        {key: '💎', text: '宝石'},
                        {key: '📿', text: '念珠'},
                        {key: '🕶', text: '墨镜'},
                        {key: '🛍', text: '购物袋'},
                        {key: '🥻', text: '纱丽'},
                        {key: '🥼', text: '白大褂'},
                        {key: '🥽', text: '护目镜'},
                        {key: '🥾', text: '登山鞋'},
                        {key: '🥿', text: '平底鞋'},
                        {key: '🦺', text: '救生衣'},
                        {key: '🧢', text: '鸭舌帽'},
                        {key: '🧣', text: '围巾'},
                        {key: '🧤', text: '手套'},
                        {key: '🧥', text: '外套'},
                        {key: '🧦', text: '袜子'},
                        {key: '🩰', text: '芭蕾舞鞋'},
                        {key: '🩱', text: '连体泳衣'},
                        {key: '🩲', text: '三角裤'},
                        {key: '🩳', text: '短裤'},
                        {key: '🩴', text: '夹趾凉鞋'},
                        {key: '🪖', text: '军用头盔'},
                    ],
                }, {
                    key: '📢', name: '声音', count: 9, items: [

                        {key: '📢', text: '喇叭'},
                        {key: '📣', text: '扩音器'},
                        {key: '📯', text: '邮号'},
                        {key: '🔇', text: '已静音的扬声器'},
                        {key: '🔈', text: '低音量的扬声器'},
                        {key: '🔉', text: '中等音量的扬声器'},
                        {key: '🔊', text: '高音量的扬声器'},
                        {key: '🔔', text: '铃铛'},
                        {key: '🔕', text: '禁止响铃'},
                    ],
                }, {
                    key: '🎵', name: '音乐', count: 9, items: [

                        {key: '🎙', text: '录音室麦克风'},
                        {key: '🎚', text: '电平滑块'},
                        {key: '🎛', text: '控制旋钮'},
                        {key: '🎤', text: '麦克风'},
                        {key: '🎧', text: '耳机'},
                        {key: '🎵', text: '音符'},
                        {key: '🎶', text: '多个音符'},
                        {key: '🎼', text: '乐谱'},
                        {key: '📻', text: '收音机'},
                    ],
                }, {
                    key: '🎹', name: '乐器', count: 9, items: [

                        {key: '🎷', text: '萨克斯管'},
                        {key: '🎸', text: '吉他'},
                        {key: '🎹', text: '音乐键盘'},
                        {key: '🎺', text: '小号'},
                        {key: '🎻', text: '小提琴'},
                        {key: '🥁', text: '鼓'},
                        {key: '🪕', text: '班卓琴'},
                        {key: '🪗', text: '手风琴'},
                        {key: '🪘', text: '长鼓'},
                    ],
                }, {
                    key: '📞', name: '电话', count: 6, items: [

                        {key: '☎', text: '电话'},
                        {key: '📞', text: '电话听筒'},
                        {key: '📟', text: '寻呼机'},
                        {key: '📠', text: '传真机'},
                        {key: '📱', text: '手机'},
                        {key: '📲', text: '带有箭头的手机'},
                    ],
                }, {
                    key: '🖱️', name: '电脑', count: 13, items: [

                        {key: '⌨', text: '键盘'},
                        {key: '💻', text: '笔记本电脑'},
                        {key: '💽', text: '电脑光盘'},
                        {key: '💾', text: '软盘'},
                        {key: '💿', text: '光盘'},
                        {key: '📀', text: 'DVD'},
                        {key: '🔋', text: '电池'},
                        {key: '🔌', text: '电源插头'},
                        {key: '🖥', text: '台式电脑'},
                        {key: '🖨', text: '打印机'},
                        {key: '🖱', text: '电脑鼠标'},
                        {key: '🖲', text: '轨迹球'},
                        {key: '🧮', text: '算盘'},
                    ],
                }, {
                    key: '💡', name: '光线&amp;视频', count: 16, items: [

                        {key: '🎞', text: '影片帧'},
                        {key: '🎥', text: '电影摄影机'},
                        {key: '🎬', text: '场记板'},
                        {key: '🏮', text: '红灯笼'},
                        {key: '💡', text: '灯泡'},
                        {key: '📷', text: '相机'},
                        {key: '📸', text: '开闪光灯的相机'},
                        {key: '📹', text: '摄像机'},
                        {key: '📺', text: '电视机'},
                        {key: '📼', text: '录像带'},
                        {key: '📽', text: '电影放映机'},
                        {key: '🔍', text: '左斜的放大镜'},
                        {key: '🔎', text: '右斜的放大镜'},
                        {key: '🔦', text: '手电筒'},
                        {key: '🕯', text: '蜡烛'},
                        {key: '🪔', text: '印度油灯'},
                    ],
                }, {
                    key: '📒', name: '图书与纸张', count: 17, items: [

                        {key: '🏷', text: '标签'},
                        {key: '📃', text: '带卷边的页面'},
                        {key: '📄', text: '文件'},
                        {key: '📑', text: '标签页'},
                        {key: '📒', text: '账本'},
                        {key: '📓', text: '笔记本'},
                        {key: '📔', text: '精装笔记本'},
                        {key: '📕', text: '合上的书本'},
                        {key: '📖', text: '打开的书本'},
                        {key: '📗', text: '绿色书本'},
                        {key: '📘', text: '蓝色书本'},
                        {key: '📙', text: '橙色书本'},
                        {key: '📚', text: '书'},
                        {key: '📜', text: '卷轴'},
                        {key: '📰', text: '报纸'},
                        {key: '🔖', text: '书签'},
                        {key: '🗞', text: '报纸卷'},
                    ],
                }, {
                    key: '💲', name: '金钱', count: 10, items: [

                        {key: '💰', text: '钱袋'},
                        {key: '💳', text: '信用卡'},
                        {key: '💴', text: '日元'},
                        {key: '💵', text: '美元'},
                        {key: '💶', text: '欧元'},
                        {key: '💷', text: '英镑'},
                        {key: '💸', text: '长翅膀的钱'},
                        {key: '💹', text: '趋势向上且带有日元符号的图表'},
                        {key: '🧾', text: '收据'},
                        {key: '🪙', text: '硬币'},
                    ],
                }, {
                    key: '✉️', name: '邮件', count: 13, items: [

                        {key: '✉', text: '信封'},
                        {key: '📤', text: '发件箱'},
                        {key: '📥', text: '收件箱'},
                        {key: '📦', text: '包裹'},
                        {key: '📧', text: '电子邮件'},
                        {key: '📨', text: '来信'},
                        {key: '📩', text: '收邮件'},
                        {key: '📪', text: '无待收信件'},
                        {key: '📫', text: '有待收信件'},
                        {key: '📬', text: '有新信件'},
                        {key: '📭', text: '无新信件'},
                        {key: '📮', text: '邮筒'},
                        {key: '🗳', text: '投票箱'},
                    ],
                }, {
                    key: '✏️', name: '书写', count: 7, items: [

                        {key: '✏', text: '铅笔'},
                        {key: '✒', text: '钢笔尖'},
                        {key: '📝', text: '备忘录'},
                        {key: '🖊', text: '笔'},
                        {key: '🖋', text: '钢笔'},
                        {key: '🖌', text: '画笔'},
                        {key: '🖍', text: '蜡笔'},
                    ],
                }, {
                    key: '✂️', name: '办公', count: 23, items: [

                        {key: '✂', text: '剪刀'},
                        {key: '💼', text: '公文包'},
                        {key: '📁', text: '文件夹'},
                        {key: '📂', text: '打开的文件夹'},
                        {key: '📅', text: '日历'},
                        {key: '📆', text: '手撕日历'},
                        {key: '📇', text: '卡片索引'},
                        {key: '📈', text: '趋势向上的图表'},
                        {key: '📉', text: '趋势向下的图表'},
                        {key: '📊', text: '条形图'},
                        {key: '📋', text: '剪贴板'},
                        {key: '📌', text: '图钉'},
                        {key: '📍', text: '圆图钉'},
                        {key: '📎', text: '回形针'},
                        {key: '📏', text: '直尺'},
                        {key: '📐', text: '三角尺'},
                        {key: '🖇', text: '连起来的两个回形针'},
                        {key: '🗂', text: '索引分隔文件夹'},
                        {key: '🗃', text: '卡片盒'},
                        {key: '🗄', text: '文件柜'},
                        {key: '🗑', text: '垃圾桶'},
                        {key: '🗒', text: '线圈本'},
                        {key: '🗓', text: '线圈日历'},
                    ],
                }, {
                    key: '🔏', name: '锁', count: 6, items: [

                        {key: '🔏', text: '墨水笔和锁'},
                        {key: '🔐', text: '钥匙和锁'},
                        {key: '🔑', text: '钥匙'},
                        {key: '🔒', text: '合上的锁'},
                        {key: '🔓', text: '打开的锁'},
                        {key: '🗝', text: '老式钥匙'},
                    ],
                }, {
                    key: '⛏️', name: '工具', count: 25, items: [

                        {key: '⚒', text: '锤子与镐'},
                        {key: '⚔', text: '交叉放置的剑'},
                        {key: '⚖', text: '天平'},
                        {key: '⚙', text: '齿轮'},
                        {key: '⛏', text: '铁镐'},
                        {key: '⛓', text: '链条'},
                        {key: '🏹', text: '弓和箭'},
                        {key: '🔗', text: '链接'},
                        {key: '🔧', text: '扳手'},
                        {key: '🔨', text: '锤子'},
                        {key: '🔩', text: '螺母与螺栓'},
                        {key: '🔫', text: '手枪'},
                        {key: '🗜', text: '夹钳'},
                        {key: '🗡', text: '匕首'},
                        {key: '🛠', text: '锤子与扳手'},
                        {key: '🛡', text: '盾牌'},
                        {key: '🦯', text: '盲杖'},
                        {key: '🧰', text: '工具箱'},
                        {key: '🧲', text: '磁铁'},
                        {key: '🪃', text: '回旋镖'},
                        {key: '🪓', text: '斧头'},
                        {key: '🪚', text: '木工锯'},
                        {key: '🪛', text: '螺丝刀'},
                        {key: '🪜', text: '梯子'},
                        {key: '🪝', text: '挂钩'},
                    ],
                }, {
                    key: '🔭', name: '科技', count: 7, items: [

                        {key: '⚗', text: '蒸馏器'},
                        {key: '📡', text: '卫星天线'},
                        {key: '🔬', text: '显微镜'},
                        {key: '🔭', text: '望远镜'},
                        {key: '🧪', text: '试管'},
                        {key: '🧫', text: '培养皿'},
                        {key: '🧬', text: 'DNA'},
                    ],
                }, {
                    key: '💊', name: '医疗', count: 5, items: [

                        {key: '💉', text: '注射器'},
                        {key: '💊', text: '药丸'},
                        {key: '🩸', text: '血滴'},
                        {key: '🩹', text: '创可贴'},
                        {key: '🩺', text: '听诊器'},
                    ],
                }, {
                    key: '🚽', name: '居家', count: 24, items: [

                        {key: '🚪', text: '门'},
                        {key: '🚽', text: '马桶'},
                        {key: '🚿', text: '淋浴'},
                        {key: '🛁', text: '浴缸'},
                        {key: '🛋', text: '沙发和灯'},
                        {key: '🛏', text: '床'},
                        {key: '🛒', text: '购物车'},
                        {key: '🛗', text: '电梯'},
                        {key: '🧯', text: '灭火器'},
                        {key: '🧴', text: '乳液瓶'},
                        {key: '🧷', text: '安全别针'},
                        {key: '🧹', text: '扫帚'},
                        {key: '🧺', text: '筐'},
                        {key: '🧻', text: '卷纸'},
                        {key: '🧼', text: '皂'},
                        {key: '🧽', text: '海绵'},
                        {key: '🪑', text: '椅子'},
                        {key: '🪒', text: '剃须刀'},
                        {key: '🪞', text: '镜子'},
                        {key: '🪟', text: '窗户'},
                        {key: '🪠', text: '活塞'},
                        {key: '🪣', text: '桶'},
                        {key: '🪤', text: '捕鼠器'},
                        {key: '🪥', text: '牙刷'},
                    ],
                }, {
                    key: '🚬', name: '其他物品', count: 6, items: [

                        {key: '⚰', text: '棺材'},
                        {key: '⚱', text: '骨灰缸'},
                        {key: '🗿', text: '摩埃'},
                        {key: '🚬', text: '香烟'},
                        {key: '🪦', text: '墓碑'},
                        {key: '🪧', text: '标语牌'},
                    ],
                }],
            }, {
                key: '🛑', name: '符号', count: 220, items: [{
                    key: '🚻', name: '交通标识', count: 13, items: [

                        {key: '♿', text: '轮椅标识'},
                        {key: '🏧', text: '取款机'},
                        {key: '🚮', text: '倒垃圾'},
                        {key: '🚰', text: '饮用水'},
                        {key: '🚹', text: '男厕'},
                        {key: '🚺', text: '女厕'},
                        {key: '🚻', text: '卫生间'},
                        {key: '🚼', text: '宝宝'},
                        {key: '🚾', text: '厕所'},
                        {key: '🛂', text: '护照检查'},
                        {key: '🛃', text: '海关'},
                        {key: '🛄', text: '提取行李'},
                        {key: '🛅', text: '寄存行李'},
                    ],
                }, {
                    key: '⚠️', name: '警告', count: 13, items: [

                        {key: '☢', text: '辐射'},
                        {key: '☣', text: '生物危害'},
                        {key: '⚠', text: '警告'},
                        {key: '⛔', text: '禁止通行'},
                        {key: '📵', text: '禁止使用手机'},
                        {key: '🔞', text: '18禁'},
                        {key: '🚫', text: '禁止'},
                        {key: '🚭', text: '禁止吸烟'},
                        {key: '🚯', text: '禁止乱扔垃圾'},
                        {key: '🚱', text: '非饮用水'},
                        {key: '🚳', text: '禁止自行车'},
                        {key: '🚷', text: '禁止行人通行'},
                        {key: '🚸', text: '儿童过街'},
                    ],
                }, {
                    key: '↩️', name: '箭头', count: 21, items: [

                        {key: '↔', text: '左右箭头'},
                        {key: '↕', text: '上下箭头'},
                        {key: '↖', text: '左上箭头'},
                        {key: '↗', text: '右上箭头'},
                        {key: '↘', text: '右下箭头'},
                        {key: '↙', text: '左下箭头'},
                        {key: '↩', text: '右转弯箭头'},
                        {key: '↪', text: '左转弯箭头'},
                        {key: '➡', text: '向右箭头'},
                        {key: '⤴', text: '右上弯箭头'},
                        {key: '⤵', text: '右下弯箭头'},
                        {key: '⬅', text: '向左箭头'},
                        {key: '⬆', text: '向上箭头'},
                        {key: '⬇', text: '向下箭头'},
                        {key: '🔃', text: '顺时针垂直箭头'},
                        {key: '🔄', text: '逆时针箭头按钮'},
                        {key: '🔙', text: '返回箭头'},
                        {key: '🔚', text: '结束箭头'},
                        {key: '🔛', text: 'ON! 箭头'},
                        {key: '🔜', text: 'SOON 箭头'},
                        {key: '🔝', text: '置顶'},
                    ],
                }, {
                    key: '☪️', name: '宗教', count: 12, items: [

                        {key: '☦', text: '东正教十字架'},
                        {key: '☪', text: '星月'},
                        {key: '☮', text: '和平符号'},
                        {key: '☯', text: '阴阳'},
                        {key: '☸', text: '法轮'},
                        {key: '⚛', text: '原子符号'},
                        {key: '✝', text: '十字架'},
                        {key: '✡', text: '六芒星'},
                        {key: '🔯', text: '带中间点的六芒星'},
                        {key: '🕉', text: '奥姆'},
                        {key: '🕎', text: '烛台'},
                        {key: '🛐', text: '宗教场所'},
                    ],
                }, {
                    key: '♈', name: '星座', count: 13, items: [

                        {key: '♈', text: '白羊座'},
                        {key: '♉', text: '金牛座'},
                        {key: '♊', text: '双子座'},
                        {key: '♋', text: '巨蟹座'},
                        {key: '♌', text: '狮子座'},
                        {key: '♍', text: '处女座'},
                        {key: '♎', text: '天秤座'},
                        {key: '♏', text: '天蝎座'},
                        {key: '♐', text: '射手座'},
                        {key: '♑', text: '摩羯座'},
                        {key: '♒', text: '水瓶座'},
                        {key: '♓', text: '双鱼座'},
                        {key: '⛎', text: '蛇夫座'},
                    ],
                }, {
                    key: '⏏️', name: '视频标志', count: 24, items: [

                        {key: '⏏', text: '推出按钮'},
                        {key: '⏩', text: '快进按钮'},
                        {key: '⏪', text: '快退按钮'},
                        {key: '⏫', text: '快速上升按钮'},
                        {key: '⏬', text: '快速下降按钮'},
                        {key: '⏭', text: '下一个音轨按钮'},
                        {key: '⏮', text: '上一个音轨按钮'},
                        {key: '⏯', text: '播放或暂停按钮'},
                        {key: '⏸', text: '暂停按钮'},
                        {key: '⏹', text: '停止按钮'},
                        {key: '⏺', text: '录制按钮'},
                        {key: '▶', text: '播放按钮'},
                        {key: '◀', text: '倒退按钮'},
                        {key: '🎦', text: '电影院'},
                        {key: '📳', text: '振动模式'},
                        {key: '📴', text: '手机关机'},
                        {key: '📶', text: '信号强度条'},
                        {key: '🔀', text: '随机播放音轨按钮'},
                        {key: '🔁', text: '重复按钮'},
                        {key: '🔂', text: '重复一次按钮'},
                        {key: '🔅', text: '低亮度按钮'},
                        {key: '🔆', text: '高亮度按钮'},
                        {key: '🔼', text: '向上三角形按钮'},
                        {key: '🔽', text: '向下三角形按钮'},
                    ],
                }, {
                    key: '♀️', name: '性别', count: 3, items: [

                        {key: '♀', text: '女性符号'},
                        {key: '♂', text: '男性符号'},
                        {key: '⚧', text: '跨性别符号'},
                    ],
                }, {
                    key: '☑️', name: '其他符号', count: 35, items: [

                        {key: '©', text: '版权'},
                        {key: '®', text: '注册'},
                        {key: '‼', text: '双感叹号'},
                        {key: '⁉', text: '感叹疑问号'},
                        {key: '™', text: '商标'},
                        {key: '☑', text: '勾选框'},
                        {key: '♻', text: '回收标志'},
                        {key: '♾', text: '无限'},
                        {key: '⚕', text: '医疗标志'},
                        {key: '⚜', text: '百合花饰'},
                        {key: '✅', text: '勾号按钮'},
                        {key: '✔', text: '勾号'},
                        {key: '✖', text: '乘'},
                        {key: '✳', text: '八轮辐星号'},
                        {key: '✴', text: '八角星'},
                        {key: '❇', text: '火花'},
                        {key: '❌', text: '叉号'},
                        {key: '❎', text: '叉号按钮'},
                        {key: '❓', text: '问号'},
                        {key: '❔', text: '白色问号'},
                        {key: '❕', text: '白色感叹号'},
                        {key: '❗', text: '感叹号'},
                        {key: '➕', text: '加号'},
                        {key: '➖', text: '减号'},
                        {key: '➗', text: '除'},
                        {key: '➰', text: '卷曲环'},
                        {key: '➿', text: '双卷曲环'},
                        {key: '⭕', text: '红色空心圆圈'},
                        {key: '〰', text: '波浪型破折号'},
                        {key: '〽', text: '庵点'},
                        {key: '💱', text: '货币兑换'},
                        {key: '💲', text: '美元符号'},
                        {key: '📛', text: '姓名牌'},
                        {key: '🔰', text: '日本新手驾驶标志'},
                        {key: '🔱', text: '三叉戟徽章'},
                    ],
                }, {
                    key: '0️⃣', name: '键帽', count: 13, items: [

                        {key: '#⃣', text: '按键: #'},
                        {key: '*⃣', text: '按键: *'},
                        {key: '0⃣', text: '按键: 0'},
                        {key: '1⃣', text: '按键: 1'},
                        {key: '2⃣', text: '按键: 2'},
                        {key: '3⃣', text: '按键: 3'},
                        {key: '4⃣', text: '按键: 4'},
                        {key: '5⃣', text: '按键: 5'},
                        {key: '6⃣', text: '按键: 6'},
                        {key: '7⃣', text: '按键: 7'},
                        {key: '8⃣', text: '按键: 8'},
                        {key: '9⃣', text: '按键: 9'},
                        {key: '🔟', text: '按键: 10'},
                    ],
                }, {
                    key: '🅰', name: '字符', count: 39, items: [

                        {key: 'ℹ', text: '信息'},
                        {key: 'Ⓜ', text: '圆圈包围的M'},
                        {key: '㊗', text: '日文的“祝贺”按钮'},
                        {key: '㊙', text: '日文的“秘密”按钮'},
                        {key: '🅰', text: 'A 型血'},
                        {key: '🅱', text: 'B 型血'},
                        {key: '🅾', text: 'O 型血'},
                        {key: '🅿', text: '停车按钮'},
                        {key: '🆎', text: 'AB 型血'},
                        {key: '🆑', text: 'CL按钮'},
                        {key: '🆒', text: 'cool按钮'},
                        {key: '🆓', text: '免费按钮'},
                        {key: '🆔', text: 'ID按钮'},
                        {key: '🆕', text: 'new按钮'},
                        {key: '🆖', text: 'NG按钮'},
                        {key: '🆗', text: 'OK按钮'},
                        {key: '🆘', text: 'SOS按钮'},
                        {key: '🆙', text: 'up按钮'},
                        {key: '🆚', text: 'VS按钮'},
                        {key: '🈁', text: '日文的“这里”按钮'},
                        {key: '🈂', text: '日文的“服务费”按钮'},
                        {key: '🈚', text: '日文的“免费”按钮'},
                        {key: '🈯', text: '日文的“预留”按钮'},
                        {key: '🈲', text: '日文的“禁止”按钮'},
                        {key: '🈳', text: '日文的“有空位”按钮'},
                        {key: '🈴', text: '日文的“合格”按钮'},
                        {key: '🈵', text: '日文的“没有空位”按钮'},
                        {key: '🈶', text: '日文的“收费”按钮'},
                        {key: '🈷', text: '日文的“月总量”按钮'},
                        {key: '🈸', text: '日文的“申请”按钮'},
                        {key: '🈹', text: '日文的“打折”按钮'},
                        {key: '🈺', text: '日文的“开始营业”按钮'},
                        {key: '🉐', text: '日文的“议价”按钮'},
                        {key: '🉑', text: '日文的“可接受”按钮'},
                        {key: '🔠', text: '输入大写拉丁字母'},
                        {key: '🔡', text: '输入小写拉丁字母'},
                        {key: '🔢', text: '输入数字'},
                        {key: '🔣', text: '输入符号'},
                        {key: '🔤', text: '输入拉丁字母'},
                    ],
                }, {
                    key: '⚪️', name: '几何', count: 34, items: [

                        {key: '▪', text: '黑色小方块'},
                        {key: '▫', text: '白色小方块'},
                        {key: '◻', text: '白色中方块'},
                        {key: '◼', text: '黑色中方块'},
                        {key: '◽', text: '白色中小方块'},
                        {key: '◾', text: '黑色中小方块'},
                        {key: '⚪', text: '白色圆'},
                        {key: '⚫', text: '黑色圆'},
                        {key: '⬛', text: '黑线大方框'},
                        {key: '⬜', text: '白线大方框'},
                        {key: '💠', text: '带圆点的菱形'},
                        {key: '🔘', text: '单选按钮'},
                        {key: '🔲', text: '黑色方形按钮'},
                        {key: '🔳', text: '白色方形按钮'},
                        {key: '🔴', text: '红色圆'},
                        {key: '🔵', text: '蓝色圆'},
                        {key: '🔶', text: '橙色大菱形'},
                        {key: '🔷', text: '蓝色大菱形'},
                        {key: '🔸', text: '橙色小菱形'},
                        {key: '🔹', text: '蓝色小菱形'},
                        {key: '🔺', text: '红色正三角'},
                        {key: '🔻', text: '红色倒三角'},
                        {key: '🟠', text: '橙色圆'},
                        {key: '🟡', text: '黄色圆'},
                        {key: '🟢', text: '绿色圆'},
                        {key: '🟣', text: '紫色圆'},
                        {key: '🟤', text: '棕色圆'},
                        {key: '🟥', text: '红色方块'},
                        {key: '🟦', text: '蓝色方块'},
                        {key: '🟧', text: '橙色方块'},
                        {key: '🟨', text: '黄色方块'},
                        {key: '🟩', text: '绿色方块'},
                        {key: '🟪', text: '紫色方块'},
                        {key: '🟫', text: '棕色方块'},
                    ],
                }],
            },
            {
                key: '🏁', name: '旗帜', count: 269, items: [{
                    key: '🚩', name: '旗子', count: 8, items: [

                        {key: '🎌', text: '交叉旗'},
                        {key: '🏁', text: '终点旗'},
                        {key: '🏳', text: '举白旗'},
                        {key: '🏳⚧', text: '跨性别旗'},
                        {key: '🏳🌈', text: '彩虹旗'},
                        {key: '🏴', text: '举黑旗'},
                        {key: '🏴☠', text: '海盗旗'},
                        {key: '🚩', text: '三角旗'},
                    ],
                }, {
                    key: '🇬🇧', name: '国家或地区旗', count: 258, items: [

                        {key: '🇦🇨', text: '旗: 阿森松岛'},
                        {key: '🇦🇩', text: '旗: 安道尔'},
                        {key: '🇦🇪', text: '旗: 阿拉伯联合酋长国'},
                        {key: '🇦🇫', text: '旗: 阿富汗'},
                        {key: '🇦🇬', text: '旗: 安提瓜和巴布达'},
                        {key: '🇦🇮', text: '旗: 安圭拉'},
                        {key: '🇦🇱', text: '旗: 阿尔巴尼亚'},
                        {key: '🇦🇲', text: '旗: 亚美尼亚'},
                        {key: '🇦🇴', text: '旗: 安哥拉'},
                        {key: '🇦🇶', text: '旗: 南极洲'},
                        {key: '🇦🇷', text: '旗: 阿根廷'},
                        {key: '🇦🇸', text: '旗: 美属萨摩亚'},
                        {key: '🇦🇹', text: '旗: 奥地利'},
                        {key: '🇦🇺', text: '旗: 澳大利亚'},
                        {key: '🇦🇼', text: '旗: 阿鲁巴'},
                        {key: '🇦🇽', text: '旗: 奥兰群岛'},
                        {key: '🇦🇿', text: '旗: 阿塞拜疆'},
                        {key: '🇧🇦', text: '旗: 波斯尼亚和黑塞哥维那'},
                        {key: '🇧🇧', text: '旗: 巴巴多斯'},
                        {key: '🇧🇩', text: '旗: 孟加拉国'},
                        {key: '🇧🇪', text: '旗: 比利时'},
                        {key: '🇧🇫', text: '旗: 布基纳法索'},
                        {key: '🇧🇬', text: '旗: 保加利亚'},
                        {key: '🇧🇭', text: '旗: 巴林'},
                        {key: '🇧🇮', text: '旗: 布隆迪'},
                        {key: '🇧🇯', text: '旗: 贝宁'},
                        {key: '🇧🇱', text: '旗: 圣巴泰勒米'},
                        {key: '🇧🇲', text: '旗: 百慕大'},
                        {key: '🇧🇳', text: '旗: 文莱'},
                        {key: '🇧🇴', text: '旗: 玻利维亚'},
                        {key: '🇧🇶', text: '旗: 荷属加勒比区'},
                        {key: '🇧🇷', text: '旗: 巴西'},
                        {key: '🇧🇸', text: '旗: 巴哈马'},
                        {key: '🇧🇹', text: '旗: 不丹'},
                        {key: '🇧🇻', text: '旗: 布韦岛'},
                        {key: '🇧🇼', text: '旗: 博茨瓦纳'},
                        {key: '🇧🇾', text: '旗: 白俄罗斯'},
                        {key: '🇧🇿', text: '旗: 伯利兹'},
                        {key: '🇨🇦', text: '旗: 加拿大'},
                        {key: '🇨🇨', text: '旗: 科科斯（基林）群岛'},
                        {key: '🇨🇩', text: '旗: 刚果（金）'},
                        {key: '🇨🇫', text: '旗: 中非共和国'},
                        {key: '🇨🇬', text: '旗: 刚果（布）'},
                        {key: '🇨🇭', text: '旗: 瑞士'},
                        {key: '🇨🇮', text: '旗: 科特迪瓦'},
                        {key: '🇨🇰', text: '旗: 库克群岛'},
                        {key: '🇨🇱', text: '旗: 智利'},
                        {key: '🇨🇲', text: '旗: 喀麦隆'},
                        {key: '🇨🇳', text: '旗: 中国'},
                        {key: '🇨🇴', text: '旗: 哥伦比亚'},
                        {key: '🇨🇵', text: '旗: 克利珀顿岛'},
                        {key: '🇨🇷', text: '旗: 哥斯达黎加'},
                        {key: '🇨🇺', text: '旗: 古巴'},
                        {key: '🇨🇻', text: '旗: 佛得角'},
                        {key: '🇨🇼', text: '旗: 库拉索'},
                        {key: '🇨🇽', text: '旗: 圣诞岛'},
                        {key: '🇨🇾', text: '旗: 塞浦路斯'},
                        {key: '🇨🇿', text: '旗: 捷克'},
                        {key: '🇩🇪', text: '旗: 德国'},
                        {key: '🇩🇬', text: '旗: 迪戈加西亚岛'},
                        {key: '🇩🇯', text: '旗: 吉布提'},
                        {key: '🇩🇰', text: '旗: 丹麦'},
                        {key: '🇩🇲', text: '旗: 多米尼克'},
                        {key: '🇩🇴', text: '旗: 多米尼加共和国'},
                        {key: '🇩🇿', text: '旗: 阿尔及利亚'},
                        {key: '🇪🇦', text: '旗: 休达及梅利利亚'},
                        {key: '🇪🇨', text: '旗: 厄瓜多尔'},
                        {key: '🇪🇪', text: '旗: 爱沙尼亚'},
                        {key: '🇪🇬', text: '旗: 埃及'},
                        {key: '🇪🇭', text: '旗: 西撒哈拉'},
                        {key: '🇪🇷', text: '旗: 厄立特里亚'},
                        {key: '🇪🇸', text: '旗: 西班牙'},
                        {key: '🇪🇹', text: '旗: 埃塞俄比亚'},
                        {key: '🇪🇺', text: '旗: 欧盟'},
                        {key: '🇫🇮', text: '旗: 芬兰'},
                        {key: '🇫🇯', text: '旗: 斐济'},
                        {key: '🇫🇰', text: '旗: 福克兰群岛'},
                        {key: '🇫🇲', text: '旗: 密克罗尼西亚'},
                        {key: '🇫🇴', text: '旗: 法罗群岛'},
                        {key: '🇫🇷', text: '旗: 法国'},
                        {key: '🇬🇦', text: '旗: 加蓬'},
                        {key: '🇬🇧', text: '旗: 英国'},
                        {key: '🇬🇩', text: '旗: 格林纳达'},
                        {key: '🇬🇪', text: '旗: 格鲁吉亚'},
                        {key: '🇬🇫', text: '旗: 法属圭亚那'},
                        {key: '🇬🇬', text: '旗: 根西岛'},
                        {key: '🇬🇭', text: '旗: 加纳'},
                        {key: '🇬🇮', text: '旗: 直布罗陀'},
                        {key: '🇬🇱', text: '旗: 格陵兰'},
                        {key: '🇬🇲', text: '旗: 冈比亚'},
                        {key: '🇬🇳', text: '旗: 几内亚'},
                        {key: '🇬🇵', text: '旗: 瓜德罗普'},
                        {key: '🇬🇶', text: '旗: 赤道几内亚'},
                        {key: '🇬🇷', text: '旗: 希腊'},
                        {key: '🇬🇸', text: '旗: 南乔治亚和南桑威奇群岛'},
                        {key: '🇬🇹', text: '旗: 危地马拉'},
                        {key: '🇬🇺', text: '旗: 关岛'},
                        {key: '🇬🇼', text: '旗: 几内亚比绍'},
                        {key: '🇬🇾', text: '旗: 圭亚那'},
                        {key: '🇭🇰', text: '旗: 中国香港特别行政区'},
                        {key: '🇭🇲', text: '旗: 赫德岛和麦克唐纳群岛'},
                        {key: '🇭🇳', text: '旗: 洪都拉斯'},
                        {key: '🇭🇷', text: '旗: 克罗地亚'},
                        {key: '🇭🇹', text: '旗: 海地'},
                        {key: '🇭🇺', text: '旗: 匈牙利'},
                        {key: '🇮🇨', text: '旗: 加纳利群岛'},
                        {key: '🇮🇩', text: '旗: 印度尼西亚'},
                        {key: '🇮🇪', text: '旗: 爱尔兰'},
                        {key: '🇮🇱', text: '旗: 以色列'},
                        {key: '🇮🇲', text: '旗: 马恩岛'},
                        {key: '🇮🇳', text: '旗: 印度'},
                        {key: '🇮🇴', text: '旗: 英属印度洋领地'},
                        {key: '🇮🇶', text: '旗: 伊拉克'},
                        {key: '🇮🇷', text: '旗: 伊朗'},
                        {key: '🇮🇸', text: '旗: 冰岛'},
                        {key: '🇮🇹', text: '旗: 意大利'},
                        {key: '🇯🇪', text: '旗: 泽西岛'},
                        {key: '🇯🇲', text: '旗: 牙买加'},
                        {key: '🇯🇴', text: '旗: 约旦'},
                        {key: '🇯🇵', text: '旗: 日本'},
                        {key: '🇰🇪', text: '旗: 肯尼亚'},
                        {key: '🇰🇬', text: '旗: 吉尔吉斯斯坦'},
                        {key: '🇰🇭', text: '旗: 柬埔寨'},
                        {key: '🇰🇮', text: '旗: 基里巴斯'},
                        {key: '🇰🇲', text: '旗: 科摩罗'},
                        {key: '🇰🇳', text: '旗: 圣基茨和尼维斯'},
                        {key: '🇰🇵', text: '旗: 朝鲜'},
                        {key: '🇰🇷', text: '旗: 韩国'},
                        {key: '🇰🇼', text: '旗: 科威特'},
                        {key: '🇰🇾', text: '旗: 开曼群岛'},
                        {key: '🇰🇿', text: '旗: 哈萨克斯坦'},
                        {key: '🇱🇦', text: '旗: 老挝'},
                        {key: '🇱🇧', text: '旗: 黎巴嫩'},
                        {key: '🇱🇨', text: '旗: 圣卢西亚'},
                        {key: '🇱🇮', text: '旗: 列支敦士登'},
                        {key: '🇱🇰', text: '旗: 斯里兰卡'},
                        {key: '🇱🇷', text: '旗: 利比里亚'},
                        {key: '🇱🇸', text: '旗: 莱索托'},
                        {key: '🇱🇹', text: '旗: 立陶宛'},
                        {key: '🇱🇺', text: '旗: 卢森堡'},
                        {key: '🇱🇻', text: '旗: 拉脱维亚'},
                        {key: '🇱🇾', text: '旗: 利比亚'},
                        {key: '🇲🇦', text: '旗: 摩洛哥'},
                        {key: '🇲🇨', text: '旗: 摩纳哥'},
                        {key: '🇲🇩', text: '旗: 摩尔多瓦'},
                        {key: '🇲🇪', text: '旗: 黑山'},
                        {key: '🇲🇫', text: '旗: 法属圣马丁'},
                        {key: '🇲🇬', text: '旗: 马达加斯加'},
                        {key: '🇲🇭', text: '旗: 马绍尔群岛'},
                        {key: '🇲🇰', text: '旗: 北马其顿'},
                        {key: '🇲🇱', text: '旗: 马里'},
                        {key: '🇲🇲', text: '旗: 缅甸'},
                        {key: '🇲🇳', text: '旗: 蒙古'},
                        {key: '🇲🇴', text: '旗: 中国澳门特别行政区'},
                        {key: '🇲🇵', text: '旗: 北马里亚纳群岛'},
                        {key: '🇲🇶', text: '旗: 马提尼克'},
                        {key: '🇲🇷', text: '旗: 毛里塔尼亚'},
                        {key: '🇲🇸', text: '旗: 蒙特塞拉特'},
                        {key: '🇲🇹', text: '旗: 马耳他'},
                        {key: '🇲🇺', text: '旗: 毛里求斯'},
                        {key: '🇲🇻', text: '旗: 马尔代夫'},
                        {key: '🇲🇼', text: '旗: 马拉维'},
                        {key: '🇲🇽', text: '旗: 墨西哥'},
                        {key: '🇲🇾', text: '旗: 马来西亚'},
                        {key: '🇲🇿', text: '旗: 莫桑比克'},
                        {key: '🇳🇦', text: '旗: 纳米比亚'},
                        {key: '🇳🇨', text: '旗: 新喀里多尼亚'},
                        {key: '🇳🇪', text: '旗: 尼日尔'},
                        {key: '🇳🇫', text: '旗: 诺福克岛'},
                        {key: '🇳🇬', text: '旗: 尼日利亚'},
                        {key: '🇳🇮', text: '旗: 尼加拉瓜'},
                        {key: '🇳🇱', text: '旗: 荷兰'},
                        {key: '🇳🇴', text: '旗: 挪威'},
                        {key: '🇳🇵', text: '旗: 尼泊尔'},
                        {key: '🇳🇷', text: '旗: 瑙鲁'},
                        {key: '🇳🇺', text: '旗: 纽埃'},
                        {key: '🇳🇿', text: '旗: 新西兰'},
                        {key: '🇴🇲', text: '旗: 阿曼'},
                        {key: '🇵🇦', text: '旗: 巴拿马'},
                        {key: '🇵🇪', text: '旗: 秘鲁'},
                        {key: '🇵🇫', text: '旗: 法属波利尼西亚'},
                        {key: '🇵🇬', text: '旗: 巴布亚新几内亚'},
                        {key: '🇵🇭', text: '旗: 菲律宾'},
                        {key: '🇵🇰', text: '旗: 巴基斯坦'},
                        {key: '🇵🇱', text: '旗: 波兰'},
                        {key: '🇵🇲', text: '旗: 圣皮埃尔和密克隆群岛'},
                        {key: '🇵🇳', text: '旗: 皮特凯恩群岛'},
                        {key: '🇵🇷', text: '旗: 波多黎各'},
                        {key: '🇵🇸', text: '旗: 巴勒斯坦领土'},
                        {key: '🇵🇹', text: '旗: 葡萄牙'},
                        {key: '🇵🇼', text: '旗: 帕劳'},
                        {key: '🇵🇾', text: '旗: 巴拉圭'},
                        {key: '🇶🇦', text: '旗: 卡塔尔'},
                        {key: '🇷🇪', text: '旗: 留尼汪'},
                        {key: '🇷🇴', text: '旗: 罗马尼亚'},
                        {key: '🇷🇸', text: '旗: 塞尔维亚'},
                        {key: '🇷🇺', text: '旗: 俄罗斯'},
                        {key: '🇷🇼', text: '旗: 卢旺达'},
                        {key: '🇸🇦', text: '旗: 沙特阿拉伯'},
                        {key: '🇸🇧', text: '旗: 所罗门群岛'},
                        {key: '🇸🇨', text: '旗: 塞舌尔'},
                        {key: '🇸🇩', text: '旗: 苏丹'},
                        {key: '🇸🇪', text: '旗: 瑞典'},
                        {key: '🇸🇬', text: '旗: 新加坡'},
                        {key: '🇸🇭', text: '旗: 圣赫勒拿'},
                        {key: '🇸🇮', text: '旗: 斯洛文尼亚'},
                        {key: '🇸🇯', text: '旗: 斯瓦尔巴和扬马延'},
                        {key: '🇸🇰', text: '旗: 斯洛伐克'},
                        {key: '🇸🇱', text: '旗: 塞拉利昂'},
                        {key: '🇸🇲', text: '旗: 圣马力诺'},
                        {key: '🇸🇳', text: '旗: 塞内加尔'},
                        {key: '🇸🇴', text: '旗: 索马里'},
                        {key: '🇸🇷', text: '旗: 苏里南'},
                        {key: '🇸🇸', text: '旗: 南苏丹'},
                        {key: '🇸🇹', text: '旗: 圣多美和普林西比'},
                        {key: '🇸🇻', text: '旗: 萨尔瓦多'},
                        {key: '🇸🇽', text: '旗: 荷属圣马丁'},
                        {key: '🇸🇾', text: '旗: 叙利亚'},
                        {key: '🇸🇿', text: '旗: 斯威士兰'},
                        {key: '🇹🇦', text: '旗: 特里斯坦-达库尼亚群岛'},
                        {key: '🇹🇨', text: '旗: 特克斯和凯科斯群岛'},
                        {key: '🇹🇩', text: '旗: 乍得'},
                        {key: '🇹🇫', text: '旗: 法属南部领地'},
                        {key: '🇹🇬', text: '旗: 多哥'},
                        {key: '🇹🇭', text: '旗: 泰国'},
                        {key: '🇹🇯', text: '旗: 塔吉克斯坦'},
                        {key: '🇹🇰', text: '旗: 托克劳'},
                        {key: '🇹🇱', text: '旗: 东帝汶'},
                        {key: '🇹🇲', text: '旗: 土库曼斯坦'},
                        {key: '🇹🇳', text: '旗: 突尼斯'},
                        {key: '🇹🇴', text: '旗: 汤加'},
                        {key: '🇹🇷', text: '旗: 土耳其'},
                        {key: '🇹🇹', text: '旗: 特立尼达和多巴哥'},
                        {key: '🇹🇻', text: '旗: 图瓦卢'},
                        {key: '🇹🇼', text: '旗: 台湾'},
                        {key: '🇹🇿', text: '旗: 坦桑尼亚'},
                        {key: '🇺🇦', text: '旗: 乌克兰'},
                        {key: '🇺🇬', text: '旗: 乌干达'},
                        {key: '🇺🇲', text: '旗: 美国本土外小岛屿'},
                        {key: '🇺🇳', text: '旗: 联合国'},
                        {key: '🇺🇸', text: '旗: 美国'},
                        {key: '🇺🇾', text: '旗: 乌拉圭'},
                        {key: '🇺🇿', text: '旗: 乌兹别克斯坦'},
                        {key: '🇻🇦', text: '旗: 梵蒂冈'},
                        {key: '🇻🇨', text: '旗: 圣文森特和格林纳丁斯'},
                        {key: '🇻🇪', text: '旗: 委内瑞拉'},
                        {key: '🇻🇬', text: '旗: 英属维尔京群岛'},
                        {key: '🇻🇮', text: '旗: 美属维尔京群岛'},
                        {key: '🇻🇳', text: '旗: 越南'},
                        {key: '🇻🇺', text: '旗: 瓦努阿图'},
                        {key: '🇼🇫', text: '旗: 瓦利斯和富图纳'},
                        {key: '🇼🇸', text: '旗: 萨摩亚'},
                        {key: '🇽🇰', text: '旗: 科索沃'},
                        {key: '🇾🇪', text: '旗: 也门'},
                        {key: '🇾🇹', text: '旗: 马约特'},
                        {key: '🇿🇦', text: '旗: 南非'},
                        {key: '🇿🇲', text: '旗: 赞比亚'},
                        {key: '🇿🇼', text: '旗: 津巴布韦'},
                    ],
                }, {
                    key: '🏴󠁧󠁢󠁥󠁮󠁧󠁿', name: '地区旗', count: 3, items: [

                        {key: '🏴󠁧󠁢󠁥󠁮󠁧󠁿', text: '旗: 英格兰'},
                        {key: '🏴󠁧󠁢󠁳󠁣󠁴󠁿', text: '旗: 苏格兰'},
                        {key: '🏴󠁧󠁢󠁷󠁬󠁳󠁿', text: '旗: 威尔士'},
                    ],
                }],
            },
        ];

        for (const c of array) {
            for (const g of c.items) {
                for (const d of g.items) {
                    this.add(d);
                }
            }
        }
    }
}

const emojiInfoBox = new EmojiInfoBox();
export default emojiInfoBox;
