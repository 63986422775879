
import './styles/lib/materialdesign-webfont/css/materialdesignicons.css';
import './styles/lib/fontawesome-free-5.14.0-web/css/all.css';
import './styles/oim/layout.css';
import './styles/oim/main.css';
import './styles/oim/list.scss';
import './styles/oim/component.scss';
import './styles/oim/chat.scss';

import Vue from 'vue';
import Component from 'vue-class-component';

import app from '@/app/App';
import LoginController from '@/app/com/main/module/business/index/controller/LoginController';
import Prompt from '@/platform/wap/common/Prompt';

@Component({
    components: {},
})
export default class App extends Vue {
    private appInfo = app;
    private isReconnect = false;

    private reconnect() {
        this.isReconnect = true;
        const back = (success: boolean, message?: string) => {
            if (!success) {
                if (message) {
                    Prompt.notice(message);
                }
            }
            this.isReconnect = false;
        };
        const lc: LoginController = app.appContext.getMaterial(LoginController);
        lc.reconnect(back);
    }
}
