export default class EmojiBox {

    private map: Map<string, string[]> = new Map<string, string[]>();
    private emoji = '';

    constructor() {
        this.init();
    }

    public getList(): string[] {
        const list: string[] = [];
        const values = this.map.values();
        for (const m of  values) {
            for (const d of  m) {
                list.push(d);
            }
        }
        return list;
    }

    private init() {
        // this.set('图标', ['🌹','🍀🍎💰📱🌙🍁🍂🍃🌷💎🔪🔫🏀⚽⚡👄👍🔥']);
        // this.set('表情', '😀😁😂😃😄😅😆😉😊😋😎😍😘😗😙😚☺😇😐😑😶😏😣😥😮😯😪😫😴😌😛😜😝😒😓😔😕😲😷😖😞😟😤😢😭😦😧😨😬😰😱😳😵😡😠');
        // this.set('人物', '👦👧👨👩👴👵👶👱👮👲👳👷👸💂🎅👰👼💆💇🙍🙎🙅🙆💁🙋🙇🙌🙏👤👥🚶🏃👯💃👫👬👭💏💑👪');
        // this.set('手势', '💪👈👉☝👆👇✌✋👌👍👎✊👊👋👏👐✍');
        // this.set('日常', '👣👀👂👃👅👄💋👓👔👕👖👗👘👙👚👛👜👝🎒💼👞👟👠👡👢👑👒🎩🎓💄💅💍🌂');
        // this.set('手机', '📱📲📶📳📴☎📞📟📠');
        // this.set('公共', '♻🏧🚮🚰♿🚹🚺🚻🚼🚾⚠🚸⛔🚫🚳🚭🚯🚱🚷🔞💈');
        // this.set('动物', '🙈🙉🙊🐵🐒🐶🐕🐩🐺🐱😺😸😹😻😼😽🙀😿😾🐈🐯🐅🐆🐴🐎🐮🐂🐃🐄🐷🐖🐗🐽🐏🐑🐐🐪🐫🐘🐭🐁🐀🐹🐰🐇🐻🐨🐼🐾🐔🐓🐣🐤🐥🐦🐧🐸🐊🐢🐍🐲🐉🐳🐋🐬🐟🐠🐡🐙🐚🐌🐛🐜🐝🐞🦋');
        // this.set('植物', '💐🌸💮🌹🌺🌻🌼🌷🌱🌲🌳🌴🌵🌾🌿🍀🍁🍂🍃');
        // this.set('自然', '🌍🌎🌏🌐🌑🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜☀🌝🌞⭐🌟🌠☁⛅☔⚡❄🔥💧🌊');
        // this.set('饮食', '🍇🍈🍉🍊🍋🍌🍍🍎🍏🍐🍑🍒🍓🍅🍆🌽🍄🌰🍞🍖🍗🍔🍟🍕🍳🍲🍱🍘🍙🍚🍛🍜🍝🍠🍢🍣🍤🍥🍡🍦🍧🍨🍩🍪🎂🍰🍫🍬🍭🍮🍯🍼☕🍵🍶🍷🍸🍹🍺🍻🍴');
        // this.set('文体', '🎪🎭🎨🎰🚣🛀🎫🏆⚽⚾🏀🏈🏉🎾🎱🎳⛳🎣🎽🎿🏂🏄🏇🏊🚴🚵🎯🎮🎲🎷🎸🎺🎻🎬');
        // this.set('恐怖', '😈👿👹👺💀☠👻👽👾💣');
        // this.set('旅游', '🌋🗻🏠🏡🏢🏣🏤🏥🏦🏨🏩🏪🏫🏬🏭🏯🏰💒🗼🗽⛪⛲🌁🌃🌆🌇🌉🌌🎠🎡🎢🚂🚃🚄🚅🚆🚇🚈🚉🚊🚝🚞🚋🚌🚍🚎🚏🚐🚑🚒🚓🚔🚕🚖🚗🚘🚚🚛🚜🚲⛽🚨🚥🚦🚧⚓⛵🚤🚢✈💺🚁🚟🚠🚡🚀🎑🗿🛂🛃🛄🛅');
        // this.set('物品', '💌💎🔪💈🚪🚽🚿🛁⌛⏳⌚⏰🎈🎉🎊🎎🎏🎐🎀🎁📯📻📱📲☎📞📟📠🔋🔌💻💽💾💿📀🎥📺📷📹📼🔍🔎🔬🔭📡💡🔦🏮📔📕📖📗📘📙📚📓📃📜📄📰📑🔖💰💴💵💶💷💸💳✉📧📨📩📤📥📦📫📪📬📭📮✏✒📝📁📂📅📆📇📈📉📊📋📌📍📎📏📐✂🔒🔓🔏🔐🔑🔨🔫🔧🔩🔗💉💊🚬🔮🚩🎌💦💨');
        // this.set('标志', '♠♥♦♣🀄🎴🔇🔈🔉🔊📢📣💤💢💬💭♨🌀🔔🔕✡✝🔯📛🔰🔱⭕✅☑✔✖❌❎➕➖➗➰➿〽✳✴❇‼⁉❓❔❕❗©®™🎦🔅🔆💯🔠🔡🔢🔣🔤🅰🆎🅱🆑🆒🆓ℹ🆔Ⓜ🆕🆖🅾🆗🅿🆘🆙🆚🈁🈂🈷🈶🈯🉐🈹🈚🈲🉑🈸🈴🈳㊗㊙🈺🈵▪▫◻◼◽◾⬛⬜🔶🔷🔸🔹🔺🔻💠🔲🔳⚪⚫🔴🔵');
        // this.set('生肖', '🐁🐂🐅🐇🐉🐍🐎🐐🐒🐓🐕🐖');
        // this.set('星座', '♈♉♊♋♌♍♎♏♐♑♒♓⛎');
        // this.set('钟表', '🕛🕧🕐🕜🕑🕝🕒🕞🕓🕟🕔🕠🕕🕡🕖🕢🕗🕣🕘🕤🕙🕥🕚🕦⌛⏳⌚⏰⏱⏲🕰');
        // this.set('心形', '💘❤💓💔💕💖💗💙💚💛💜💝💞💟❣');
        // this.set('花草', '💐🌸💮🌹🌺🌻🌼🌷🌱🌿🍀');
        // this.set('树叶', '🌿🍀🍁🍂🍃');
        // this.set('月亮', '🌑🌒🌓🌔🌕🌖🌗🌘🌙🌚🌛🌜🌝');
        // this.set('水果', '🍇🍈🍉🍊🍋🍌🍍🍎🍏🍐🍑🍒🍓');
        // this.set('钱币', '💴💵💶💷💰💸💳');
        // this.set('交通', '🚂🚃🚄🚅🚆🚇🚈🚉🚊🚝🚞🚋🚌🚍🚎🚏🚐🚑🚒🚓🚔🚕🚖🚗🚘🚚🚛🚜🚲⛽🚨🚥🚦🚧⚓⛵🚣🚤🚢✈💺🚁🚟🚠🚡🚀');
        // this.set('建筑', '🏠🏡🏢🏣🏤🏥🏦🏨🏩🏪🏫🏬🏭🏯🏰💒🗼🗽⛪🌆🌇🌉');
        // this.set('办公', '📱📲☎📞📟📠🔋🔌💻💽💾💿📀🎥📺📷📹📼🔍🔎🔬🔭📡📔📕📖📗📘📙📚📓📃📜📄📰📑🔖💳✉📧📨📩📤📥📦📫📪📬📭📮✏✒📝📁📂📅📆📇📈📉📊📋📌📍📎📏📐✂🔒🔓🔏🔐🔑');
        // this.set('箭头', '⬆↗➡↘⬇↙⬅↖↕↔↩↪⤴⤵🔃🔄🔙🔚🔛🔜🔝');


        this.set('表情', ['😀', '😁', '😂', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙', '😚', '☺', '😇', '😐', '😑', '😶', '😏', '😣', '😥', '😮', '😯', '😪', '😫', '😴', '😌', '😛', '😜', '😝', '😒', '😓', '😔', '😕', '😲', '😷', '😖', '😞', '😟', '😤', '😢', '😭', '😦', '😧', '😨', '😬', '😰', '😱', '😳', '😵', '😡', '😠']);
        this.set('人物', ['👦', '👧', '👨', '👩', '👴', '👵', '👶', '👱', '👮', '👲', '👳', '👷', '👸', '💂', '🎅', '👰', '👼', '💆', '💇', '🙍', '🙎', '🙅', '🙆', '💁', '🙋', '🙇', '🙌', '🙏', '👤', '👥', '🚶', '🏃', '👯', '💃', '👫', '👬', '👭', '💏', '💑', '👪']);
        this.set('手势', ['💪', '👈', '👉', '☝', '👆', '👇', '✌', '✋', '👌', '👍', '👎', '✊', '👊', '👋', '👏', '👐', '✍']);
        this.set('日常', ['👣', '👀', '👂', '👃', '👅', '👄', '💋', '👓', '👔', '👕', '👖', '👗', '👘', '👙', '👚', '👛', '👜', '👝', '🎒', '💼', '👞', '👟', '👠', '👡', '👢', '👑', '👒', '🎩', '🎓', '💄', '💅', '💍', '🌂']);
        this.set('手机', ['📱', '📲', '📶', '📳', '📴', '☎', '📞', '📟', '📠']);
        this.set('公共', ['♻', '🏧', '🚮', '🚰', '♿', '🚹', '🚺', '🚻', '🚼', '🚾', '⚠', '🚸', '⛔', '🚫', '🚳', '🚭', '🚯', '🚱', '🚷', '🔞', '💈']);
        this.set('动物', ['🙈', '🙉', '🙊', '🐵', '🐒', '🐶', '🐕', '🐩', '🐺', '🐱', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🐈', '🐯', '🐅', '🐆', '🐴', '🐎', '🐮', '🐂', '🐃', '🐄', '🐷', '🐖', '🐗', '🐽', '🐏', '🐑', '🐐', '🐪', '🐫', '🐘', '🐭', '🐁', '🐀', '🐹', '🐰', '🐇', '🐻', '🐨', '🐼', '🐾', '🐔', '🐓', '🐣', '🐤', '🐥', '🐦', '🐧', '🐸', '🐊', '🐢', '🐍', '🐲', '🐉', '🐳', '🐋', '🐬', '🐟', '🐠', '🐡', '🐙', '🐚', '🐌', '🐛', '🐜', '🐝', '🐞', '🦋']);
        this.set('植物', ['💐', '🌸', '💮', '🌹', '🌺', '🌻', '🌼', '🌷', '🌱', '🌲', '🌳', '🌴', '🌵', '🌾', '🌿', '🍀', '🍁', '🍂', '🍃']);
        this.set('图标', ['🌹', '🍀', '🍎', '💰', '📱', '🌙', '🍁', '🍂', '🍃', '🌷', '💎', '🔪', '🔫', '🏀', '⚽', '⚡', '👄', '👍', '🔥']);
        this.set('自然', ['🌍', '🌎', '🌏', '🌐', '🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘', '🌙', '🌚', '🌛', '🌜', '☀', '🌝', '🌞', '⭐', '🌟', '🌠', '☁', '⛅', '☔', '⚡', '❄', '🔥', '💧', '🌊']);
        this.set('饮食', ['🍇', '🍈', '🍉', '🍊', '🍋', '🍌', '🍍', '🍎', '🍏', '🍐', '🍑', '🍒', '🍓', '🍅', '🍆', '🌽', '🍄', '🌰', '🍞', '🍖', '🍗', '🍔', '🍟', '🍕', '🍳', '🍲', '🍱', '🍘', '🍙', '🍚', '🍛', '🍜', '🍝', '🍠', '🍢', '🍣', '🍤', '🍥', '🍡', '🍦', '🍧', '🍨', '🍩', '🍪', '🎂', '🍰', '🍫', '🍬', '🍭', '🍮', '🍯', '🍼', '☕', '🍵', '🍶', '🍷', '🍸', '🍹', '🍺', '🍻', '🍴']);
        this.set('文体', ['🎪', '🎭', '🎨', '🎰', '🚣', '🛀', '🎫', '🏆', '⚽', '⚾', '🏀', '🏈', '🏉', '🎾', '🎱', '🎳', '⛳', '🎣', '🎽', '🎿', '🏂', '🏄', '🏇', '🏊', '🚴', '🚵', '🎯', '🎮', '🎲', '🎷', '🎸', '🎺', '🎻', '🎬']);
        this.set('恐怖', ['😈', '👿', '👹', '👺', '💀', '☠', '👻', '👽', '👾', '💣']);
        this.set('旅游', ['🌋', '🗻', '🏠', '🏡', '🏢', '🏣', '🏤', '🏥', '🏦', '🏨', '🏩', '🏪', '🏫', '🏬', '🏭', '🏯', '🏰', '💒', '🗼', '🗽', '⛪', '⛲', '🌁', '🌃', '🌆', '🌇', '🌉', '🌌', '🎠', '🎡', '🎢', '🚂', '🚃', '🚄', '🚅🚆', '🚇', '🚈', '🚉', '🚊', '🚝', '🚞', '🚋', '🚌', '🚍', '🚎', '🚏', '🚐', '🚑', '🚒', '🚓', '🚔', '🚕', '🚖', '🚗', '🚘', '🚚', '🚛', '🚜', '🚲', '⛽', '🚨', '🚥', '🚦', '🚧⚓', '⛵', '🚤', '🚢', '✈', '💺', '🚁', '🚟', '🚠', '🚡', '🚀', '🎑', '🗿', '🛂', '🛃', '🛄', '🛅']);
        this.set('物品', ['💌', '💎', '🔪', '💈', '🚪', '🚽', '🚿', '🛁', '⌛', '⏳', '⌚', '⏰', '🎈', '🎉', '🎊', '🎎', '🎏', '🎐', '🎀', '🎁', '📯', '📻', '📱', '📲', '☎', '📞', '📟', '📠', '🔋', '🔌', '💻', '💽', '💾', '💿', '📀', '🎥', '📺', '📷', '📹', '📼', '🔍', '🔎', '🔬', '🔭', '📡', '💡', '🔦', '🏮', '📔', '📕', '📖', '📗', '📘', '📙', '📚', '📓', '📃', '📜', '📄', '📰', '📑', '🔖', '💰', '💴', '💵', '💶', '💷', '💸', '💳', '✉', '📧', '📨', '📩', '📤', '📥', '📦', '📫', '📪', '📬', '📭', '📮', '✏', '✒', '📝', '📁', '📂', '📅', '📆', '📇', '📈', '📉', '📊', '📋', '📌', '📍', '📎', '📏', '📐', '✂', '🔒', '🔓', '🔏', '🔐', '🔑', '🔨', '🔫', '🔧', '🔩', '🔗', '💉', '💊', '🚬', '🔮', '🚩', '🎌', '💦', '💨']);
        this.set('标志', ['♠', '♥', '♦', '♣', '🀄', '🎴', '🔇', '🔈', '🔉', '🔊', '📢', '📣', '💤', '💢', '💬', '💭', '♨', '🌀', '🔔', '🔕', '✡', '✝', '🔯', '📛', '🔰', '🔱', '⭕', '✅', '☑', '✔', '✖', '❌', '❎', '➕', '➖', '➗', '➰', '➿', '〽', '✳', '✴', '❇', '‼', '⁉', '❓', '❔', '❕', '❗', '©', '®', '™', '🎦', '🔅', '🔆', '💯', '🔠', '🔡', '🔢', '🔣', '🔤', '🅰', '🆎', '🅱', '🆑', '🆒', '🆓', 'ℹ', '🆔', 'Ⓜ', '🆕', '🆖', '🅾', '🆗', '🅿', '🆘', '🆙', '🆚', '🈁', '🈂', '🈷', '🈶', '🈯', '🉐', '🈹', '🈚', '🈲', '🉑', '🈸', '🈴', '🈳', '㊗', '㊙', '🈺', '🈵', '▪', '▫', '◻', '◼', '◽', '◾', '⬛', '⬜', '🔶', '🔷', '🔸', '🔹', '🔺', '🔻', '💠', '🔲', '🔳', '⚪', '⚫', '🔴', '🔵']);
        this.set('生肖', ['🐁', '🐂', '🐅', '🐇', '🐉', '🐍', '🐎', '🐐', '🐒', '🐓', '🐕', '🐖']);
        this.set('星座', ['♈', '♉', '♊', '♋', '♌', '♍', '♎', '♏', '♐', '♑', '♒', '♓', '⛎']);
        this.set('钟表', ['🕛', '🕧', '🕐', '🕜', '🕑', '🕝', '🕒', '🕞', '🕓', '🕟', '🕔', '🕠', '🕕', '🕡', '🕖', '🕢', '🕗', '🕣', '🕘', '🕤', '🕙', '🕥', '🕚', '🕦', '⌛', '⏳', '⌚', '⏰', '⏱', '⏲', '🕰']);
        this.set('心形', ['💘', '❤', '💓', '💔', '💕', '💖', '💗', '💙', '💚', '💛', '💜', '💝', '💞', '💟', '❣']);
        this.set('花草', ['💐', '🌸', '💮', '🌹', '🌺', '🌻', '🌼', '🌷', '🌱', '🌿', '🍀']);
        this.set('树叶', ['🌿', '🍀', '🍁', '🍂', '🍃']);
        this.set('月亮', ['🌑', '🌒', '🌓', '🌔', '🌕', '🌖', '🌗', '🌘', '🌙', '🌚', '🌛', '🌜', '🌝']);
        this.set('水果', ['🍇', '🍈', '🍉', '🍊', '🍋', '🍌', '🍍', '🍎', '🍏', '🍐', '🍑', '🍒', '🍓']);
        this.set('钱币', ['💴', '💵', '💶', '💷', '💰', '💸', '💳']);
        this.set('交通', ['🚂', '🚃', '🚄', '🚅', '🚆', '🚇', '🚈', '🚉', '🚊', '🚝', '🚞', '🚋', '🚌', '🚍', '🚎', '🚏', '🚐', '🚑', '🚒', '🚓', '🚔', '🚕', '🚖', '🚗', '🚘', '🚚', '🚛', '🚜', '🚲', '⛽', '🚨', '🚥', '🚦', '🚧', '⚓', '⛵', '🚣', '🚤', '🚢', '✈', '💺', '🚁', '🚟', '🚠', '🚡', '🚀']);
        this.set('建筑', ['🏠', '🏡', '🏢', '🏣', '🏤', '🏥', '🏦', '🏨', '🏩', '🏪', '🏫', '🏬', '🏭', '🏯', '🏰', '💒', '🗼', '🗽', '⛪', '🌆', '🌇', '🌉']);
        this.set('办公', ['📱', '📲', '☎', '📞', '📟', '📠', '🔋', '🔌', '💻', '💽', '💾', '💿', '📀', '🎥', '📺', '📷', '📹', '📼', '🔍', '🔎', '🔬', '🔭', '📡', '📔', '📕', '📖', '📗', '📘', '📙', '📚', '📓', '📃', '📜', '📄', '📰', '📑', '🔖', '💳', '✉', '📧', '📨', '📩', '📤', '📥', '📦', '📫', '📪', '📬', '📭', '📮', '✏', '✒', '📝', '📁', '📂', '📅', '📆', '📇', '📈', '📉', '📊', '📋', '📌', '📍', '📎', '📏', '📐', '✂', '🔒', '🔓', '🔏', '🔐', '🔑']);
        this.set('箭头', ['⬆', '↗', '➡', '↘', '⬇', '↙', '⬅', '↖', '↕', '↔', '↩', '↪', '⤴', '⤵', '🔃', '🔄', '🔙', '🔚', '🔛', '🔜', '🔝']);
    }

    private set(key: string, value: string[]) {
        this.map.set(key, value);
    }
}
