export default class AppData {
    public static APP_NAME: string = 'OIM-E';
    public static APP_VERSION: string = '1.0.0';
    public static APP_BUILD: number = 1;
    // TODO
    public static APP_TYPE: string = '2';
    public static APP_PLATFORM: string = 'Windows 10';

    public static API_VERSION: string = '1';
}
