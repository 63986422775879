class MessageTimeSetting {

    /**
     * 聊天时间合并时长
     */
    public mergeMillisecond: number = 1000 * 60 * 5;

}

export default MessageTimeSetting;
